﻿
// COLORS
$brand-main: #0066CC !default;
$brand-minor: #BABEC1 !default;

$brand-paint: #003366 !default;
$brand-soft: #F9F2F4 !default;

$brand-lighter: #F9F9F9 !default;
$brand-light: #F2F2F2 !default;
$brand-gray-lighten: lighten(#828282, 15%) !default;
$brand-gray: #828282 !default;
$brand-gray-darken: darken(#828282, 15%) !default;
$brand-dark: #444444 !default;

$brand-green: #47B668 !default;

$accompany-green: #349B9E !default;
$accompany-green-light: #96C4BE !default;
$accompany-purple: #715386 !default;
$accompany-purple-light: #AE9CBB !default;
$accompany-blue: #003366 !default;
$accompany-blue-light: #8CACC6 !default;

$dropcap-orange: #F18923 !default;
$dropcap-orange-light: #FAB788 !default;
$dropcap-pink: #D43A66 !default;
$dropcap-pink-light: #E993A9 !default;

//temp var from IP, POC Palatine > ProfilRisque
$brand-gray-dark: $brand-gray-darken;
$brand-gray-light: $brand-gray-lighten;




//HEADER
$brand-main-header: $brand-main !default;
$brand-minor-header: $brand-minor !default;
$brand-paint-header: $brand-paint !default;

// indicateurs, quartile
$min-width-quartile: 12px;
$max-width-quatile: 15px;

// BUTTONS
$btn-main-color: $brand-lighter;
$btn-main-bg: $brand-main;
$btn-main-border: $brand-main;

$btn-minor-color: $brand-lighter;
$btn-minor-bg: $brand-minor;
$btn-minor-border: $brand-minor;

$btn-paint-color: $brand-lighter;
$btn-paint-bg: $brand-paint;
$btn-paint-border: $brand-paint;

$btn-disabled-color: $brand-dark;
$btn-disabled-bg: $brand-gray-lighten;
$btn-disabled-border: $brand-gray-darken;

//INPUT
$space-between-input-label: 5px;

$label-color: $brand-gray-darken;
$label-hover-color: $brand-dark;
$label-checked-color: #000;

$input-border: $brand-minor; // variable bootstrap par défaut
$input-focus-glow: lighten($brand-main, 11%);

// RADIO
$range-radio-main-color: $btn-main-color;
$range-radio-main-bg: $btn-main-bg;
$range-radio-main-border: $brand-paint;

$radio-size: 12px;
$radio-color: $brand-main;
$radio-bg-color: $brand-soft;
$radio-border-color: $brand-gray;

$radio-border-color-onfocus: $brand-main;
$radio-shadow-onfocus: inset 0 1px 1px rgba(#fff, 0.1), 0 0 6px rgba($brand-main, 0.7);

// CHECKBOX
$checkbox-size: 13px;
$checkbox-color: $brand-main;
$checkbox-bg-color: $brand-soft;
$checkbox-border-color: $brand-gray;

$checkbox-border-color-onfocus: $brand-main;
$checkbox-shadow-onfocus: inset 0 1px 1px rgba(#fff, 0.1), 0 0 8px rgba($brand-main, 0.7);

// TEXT
$content-font-family: "Arial", sans-serif;
$title-font-family: $content-font-family;
$label-font-family: $content-font-family;

// Arial Black, c'est vraiment fat. Mais c'était dans la charte...
// $content-strong-font-family: "Arial Black", "Arial", sans-serif;

$content-strong-font-family: "Arial", sans-serif;
$title-strong-font-family: $content-strong-font-family;

$content-em-font-family: "Arial Narrow", "Arial", sans-serif;

$content-font-size: 14px;
$title-font-size: 18px;
$table-font-size: 12px;

$font-size-h1: $title-font-size;
$font-size-h2: $title-font-size;
$font-size-h3: $title-font-size;
$font-size-h4: $content-font-size;
$font-size-h5: $content-font-size;
$font-size-h6: $content-font-size;
$font-size-legend: $content-font-size;

$content-color: #000;
$title-color: $brand-paint;

$font-weight-1: 100;
$font-weight-2: 200;
$font-weight-3: 300;
$font-weight-4: 400;
$font-weight-5: 500;
$font-weight-6: 600;
$font-weight-7: 700;
$font-weight-7: bold;
$font-weight-8: 800;
$font-weight-9: 900;

$text-main-weight: bold;
$text-strong-weight: 500;

$title-bottom-sm: 40px;
$title-bottom-md: 80px;
$title-bottom-thickness: 4px;
$title-bottom-color: $brand-main;
/*$title-bottom-lg: 4px;*/

// PANEL
$fancy-panel-corner-size: 15px;
$panel-heading-color: $brand-paint;
$panel-background-color: #fff;

$panel-color-bg: $brand-main;
$panel-color-text: #fff;
$panel-color-border: $brand-light;

// NAV
$tab-color: $brand-gray-lighten;
$tab-active-color: $brand-gray-darken;

//TABLE
$color-table-stripped: rgb(233,242,244);

//SPACING
$row-layout-margin: 3px;
$row-layout-padding: $row-layout-margin;

$spacer: 10px !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: ( n2: ( x: ($spacer-x * -1.5), y: ($spacer-y * -1.5) ), n1: ( x: ($spacer-x * -1), y: ($spacer-y * -1) ), 0: ( x: 0, y: 0 ), h: ( x: $spacer-x / 2, y: $spacer-y / 2 ), 1: ( x: $spacer-x, y: $spacer-y ), 2: ( x: ($spacer-x * 1.5), y: ($spacer-y * 1.5) ), 3: ( x: ($spacer-x * 2), y: ($spacer-y * 2) ), 4: ( x: ($spacer-x * 3), y: ($spacer-y * 3) ), l: (x: $row-layout-margin, y: $row-layout-margin) ) !default;




// GRAPHS
$graph-bar-small-height: 6px;
$graph-bar-medium-height: 20px;
$graph-bar-large-high-height: 30px; //Aïe Aïe !
$graph-bar-vertical-barre-height: 10px; //Il s'agit de 8px DE PLUS que la hauteur du graph. A utiliser comme ceci : height:$graph-bar-medium-height + $graph-bar-vertical-barre-height
$graph-arrow-medium-width: 10px;

// AMCHARTS
$amchart-text-color: $brand-main !default;
$amchart-legend-color: $amchart-text-color;
$amchart-title-color: $amchart-text-color;
$amchart-axis-title-color: $amchart-text-color;

$amchart-border-color: $brand-main !default;
$amchart-axis-color: $amchart-border-color;
$amchart-axis-tick-color: $amchart-border-color;

//CONTAINER
$container-min-width: 240px;

//ANIMATIONS
$timing-default: cubic-bezier(0.4, 0.0, 0.2, 1);
$transition-default: all 0.25s $timing-default;

// UTILITIES
$image-path: "../img/";


//TYPEAHEAD
$typeahead-font: $content-font-family;
$typeahead-active-bg: #eaeaea !default;
$typeahead-active-text-color: #3c3c3c !default;

//TRIPLE-STATE SWITCH
$triple-switch-no: #ff4d35 !default;
$triple-switch-yes: #68c527 !default;
$triple-switch-off: $brand-gray-lighten !default;
$triple-switch-toggle: #FFFFFF;

//*===================================================================[ A overrider par les partenaires ]============================================*/





