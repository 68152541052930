﻿/// <reference path="../_variables.scss" / >
/// <reference path="../_mixins.scss" / >

// Switch with three states (N/-/Y)

/* HTML attendu
	Changer les @ en fonction du contexte
 * <div Class="triple-switch">
 * 	  <input type="radio" name="@NomChamp" class="triple-switch-no" id="@triple-switch-no" value="true" />
 * 	  <label class="triple-switch-no" for="@triple-switch-no"></label>
 * 	  
 * 	  <input type="radio" name="@NomChamp" class="triple-switch-off" checked id="@triple-switch-neutral"  value=""/>
 * 	  <label class="triple-switch-off" for="@triple-switch-neutral"></label>
 * 	  
 * 	  <input type="radio" name="@NomChamp" class="triple-switch-yes" id="@triple-switch-yes"  value="false"/>
 * 	  <label class="triple-switch-yes" for="@triple-switch-yes"></label>
 * 	  
 * 	  <div class="triple-switch-background"></div>
 * 	  <div class="triple-switch-toggle"></div>
 * </div>
 */


$h: 13px;
$w: 30px;
$tw: $h - 4px;

.triple-switch {
	position: relative;
	height: $h;
	width: $w;
	border-radius: $h/2;
	@include user-select(none);
	display: inline-block;

	& .triple-switch-toggle {
		width: $tw;
		height: $tw;
		position: absolute;
		left: $w / 3 + (($w/3) - $tw) / 2;
		top: ($h - $tw) / 2;
		border-radius: 50%;
		box-sizing: border-box;
		background-color: $triple-switch-toggle;
		transition: left 0.2s ease-in 0s;
	}

	& .triple-switch-background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		@include user-select(none);
		border-radius: $h/2;
		background-color: $triple-switch-off;
		transition: background-color 0.2s ease-in 0s;
	}

	& label {
		cursor: pointer;
		width: $w/3;
		height: $h;
		position: absolute;
		margin: 0;
		padding: 0;
		z-index: 1;
		display: inline-block;
	}

	& input {
		display: none;
	}

	& .triple-switch-off {
		left: 100% * 1 / 3;
	}

	& .triple-switch-yes {
		left: 100% * 2 / 3;
	}

	& .triple-switch-yes:checked {
		~ .triple-switch-background {
			background-color: $triple-switch-yes;
		}

		~ .triple-switch-toggle {
			left: $w - $tw - ($h - $tw) / 2;
		}
	}

	& .triple-switch-no:checked {

		~ .triple-switch-background {
			background-color: $triple-switch-no;
		}

		~ .triple-switch-toggle {
			left: ($h - $tw) / 2;
		}
	}
}
