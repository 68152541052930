﻿/// <reference path="../variables">
/// <reference path="../../bootstrap/variables">
/// <reference path="../mixins">

/*.inside-animated {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.toggle-animate {
    display: none;
    position: absolute;
    width: 100%;
    float: left;
    z-index: 0;

    &.active {
        display: block;
        position: relative;
        z-index: 1;
    }

    &.velocity-animating {
        display: block;
    }
}*/

.fancy-switch-selected {
	color: $brand-main !important;
}

.fs-target {
	position: absolute;
	opacity: 0;
	width: 0%;

	&.fs-active {
		position: relative;
		opacity: 1;
		width: 100%;
	}
}

.onoffswitch {
	position: relative;
	width: 30px;
	@include user-select(none);
	vertical-align: middle;
	display: inline-block;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0px solid $brand-main;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 13px;
    padding: 0;
    line-height: 10px;
    font-size: 14px;
    color: white;
    //font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "";
    padding-left: 10px;
    background-color: $brand-main;
    color: #FFFFFF;
}

.onoffswitch-inner:after {
    content: "";
    padding-right: 10px;
    background-color: $brand-main;
    color: #999999;
    text-align: right;
}

.onoffswitch-deactivated:after {
    background-color: #999999;
}

.onoffswitch-switch {
    display: block;
    width: 14px;
    margin: 0px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    border: 2px solid $brand-main;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    //margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

.onoffswitch > label {
    margin-bottom: 0;
}

.onoffswitch-colorchange {
	& .onoffswitch-inner:after {
		background-color: $brand-gray-light;
/*		border: 2px solid $brand-main;
		border-radius: 20px;*/
		transition: all 0.2s ease-in 0s;
	}

	& .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner:after {
		background-color: $brand-main;
	}
}

//varaition grise
.onoffswitch-gray{
    .onoffswitch-inner:before {
        background-color: #a5a3a3;
    }

    .onoffswitch-inner:after {
        background-color: #a5a3a3;
    }

    .onoffswitch-switch{
        border: 2px solid #464450;
    }
}

//Variante couleur inversées
.onoffswitch-inverted {
	.onoffswitch-inner:before {
		background-color: #FFFFFF;
	}

	.onoffswitch-inner:after {
		background-color: #FFFFFF;
	}

	.onoffswitch-switch {
		background: $brand-main;
		border: 2px solid #FFFFFF;
	}
}
