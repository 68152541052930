﻿/// <reference path="../variables">

// Contient les couleurs des graphs AmCharts dans les pages.

// Couleur de la légende des axes
.amcharts-axis-label {
    fill: $amchart-legend-color;
}

// Couleur du symétrique des axes (nord-est et nord)
.amcharts-plot-area {
    stroke: $amchart-border-color;
}

// Couleur des axes
.amcharts-axis-line {
    stroke: $amchart-axis-color;
}

// Couleur des ticks des axes (devrait être la meme que amcharts-axis-line)
.amcharts-axis-tick {
    stroke: $amchart-axis-tick-color;
}

// Couleur du titre des axes
.amcharts-axis-title {
    fill: $amchart-axis-title-color;
}

// Couleur du titre du graph
.amcharts-title {
    fill: $amchart-title-color;
}

// Couleur des labels sur les graphs
/*.amcharts-graph-label {
	fill: red;
}*/