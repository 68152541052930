﻿
ul.parsley-errors-list{
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    text-align: center;

    & > li{
        list-style: none;

        /*Bootstrap alert-danger*/
        padding: 5px;
        border: 1px solid transparent;
        border-radius: $alert-border-radius;

        @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
    }
}

.parsley-error{

    /*Bootstrap .has-error.form-control*/
    border-color: $state-danger-text;
    &:focus {
      border-color: darken($state-danger-text, 10%);
      $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($state-danger-text, 20%);
      @include box-shadow($shadow);
    }
}