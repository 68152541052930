﻿/// <reference path="../variables">
/// <reference path="../mixins">

input[type=checkbox].fancy {
    opacity: 0;
    z-index: -1;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & + label {
        padding-left: $checkbox-size + $space-between-input-label;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        line-height: $checkbox-size;
        font-weight: bold;
        cursor: pointer;
        color: $label-color;

        &:before {
            content: " ";
            display: inline-block;
            position: absolute;
            left: 0;
            padding: 0;
            width: $checkbox-size;
            height: $checkbox-size;
            margin-right: $space-between-input-label;
            border: 2px solid $checkbox-border-color;
            background: $checkbox-bg-color;
            color: $checkbox-color;
            text-align: center;
            line-height: $checkbox-size;
        }
    }

    &:checked + label {
        color: $label-checked-color;
    }

    &:checked + label:before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
    }
}

@mixin fancy-checkbox-color-variant($text-color, $text-checked-color, $border-color, $bg-color, $checked-color) {
    color: $text-color;

    & input {
        & ~ .fancy-content:before {
            background: $bg-color;
            border-color: $border-color;
        }

        &:checked {
            & ~ .fancy-content {
                color: $text-checked-color;

                &:before {
                    color: $checked-color;
                }
            }
        }

        &:focus, &:hover:not([disabled]) {
            & ~ .fancy-content:before {
                border-color: $checked-color;
                box-shadow: inset 0 1px 1px rgba(#fff, 0.1), 0 0 8px rgba($checked-color, 0.7);
            }
        }

        &[disabled], &.disabled {
            & ~ .fancy-content {
                cursor: not-allowed;
                opacity: 0.75;
            }
        }
    }
}

label.fancy-checkbox {
	padding-left: $checkbox-size + $space-between-input-label;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	min-height: $checkbox-size;
	line-height: $checkbox-size;
	margin-bottom: 0;
	margin-right: 7px;
	@include clickable();
	// utiliser plutot fancy-checkbox-top
	&.fancy-content-bottom {
		margin-top: 20px;

		& span.fancy-content {
			&:before {
				transform: translate(0, -140%);
				left: 50%;
			}

			&:after {
				transform: translate(0, -140%) !important;
				left: 50% !important;
			}
		}
	}
	// Nouvelle version de fancy-content-bottom qui fix les margins/padding bizarres héritées du cas checkbox à gauche
	&.fancy-checkbox-top {
		margin-top: 20px;
		padding-left: initial;
		margin-right: initial;
		text-align: center;

		& span.fancy-content {
			&:before {
				transform: translate(-50%, -140%);
				left: 50%;
			}

			&:after {
				transform: translate(-50%, -140%) !important;
				left: 50% !important;
			}
		}
	}

	& input {
		position: absolute;
		z-index: -1;
		opacity: 0;

		& ~ .fancy-content {
			&:before {
				content: " ";
				display: inline-block;
				position: absolute;
				left: 0;
				width: $checkbox-size;
				height: $checkbox-size;
				margin-right: $space-between-input-label;
				border-style: solid;
				border-width: 2px;
				text-align: center;
				vertical-align: middle;
				@include transition(all 0.18s $timing-default);
			}

			&:after {
				content: " ";
				display: block;
				position: absolute;
				@include transform(scale(0));
				@include transition($transition-default);
			}
		}

		&:checked {
			& ~ .fancy-content {

				&:after {
					left: 2px;
					top: 1px;
					content: "\f00c";
					font-family: "Font Awesome 5 Pro";
					font-size: 12px;
					color: $checkbox-color;

					@include transform(scale(1));
				}
			}
		}
	}

	&.fancy-checkbox-right {
		padding-left: 0;
		padding-right: $checkbox-size + $space-between-input-label;
		text-align: right;

		& input ~ .fancy-content:before {
			left: auto;
			right: -$space-between-input-label;
		}

		& input ~ .fancy-content:after {
			left: auto;
			right: 0;
		}
	}

	&.fancy-light {
		font-weight: normal;
	}
	// Par défaut, on laisse les couleurs en blanc
	@include fancy-checkbox-color-variant($label-color, $label-checked-color, $checkbox-border-color, $checkbox-bg-color, $brand-main);
}

.fancy-checkbox.dark {
    @include fancy-checkbox-color-variant($brand-gray-lighten, white, $checkbox-border-color, white, $brand-main);
}

.checkbox-list {
	position: relative;
	display: block;
	margin-top: 10px;

	input[type=checkbox].fancy {
		& + label {
			margin-bottom: $checkbox-size + 1px;
		}
	}

	& label.fancy-checkbox {
		display: block;
		margin-bottom: $checkbox-size + 1px;
	}

	& .list-item {
		margin-left: $checkbox-size + $space-between-input-label;
	}
}

.checkbox-list-compact {
	position: relative;
	display: block;

	input[type=checkbox].fancy {
		& + label {
			margin-bottom: $checkbox-size/2 + 1px;
		}
	}

	& label.fancy-checkbox {
		display: block;
		margin-bottom: $checkbox-size/2 + 1px;
	}

	& .list-item {
		margin-left: $checkbox-size/2 + $space-between-input-label;
	}
}

/*.has-error {
    & .fancy-content:before {
        border-color: $brand-danger !important; //Désolé Clem :/
        background-color: lighten($brand-danger, 30%) !important;
    }
}*/
