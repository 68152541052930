﻿/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />


/*
    Use it like this:

    <div class="timer-container">
    <h2>4'</h2>
    <svg class="timer-svg" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
        <g>
            <title>Inside</title>
            <circle id="circle_inside" class="circle_inside" r="30" cy="50" cx="50" />
        </g>
        <g>
            <title>Background</title>
            <circle id="circle_bg" class="circle_animation_bg" r="40" cy="50" cx="50" />
        </g>
        <g>
            <title>Layer 1</title>
            <circle id="circle" class="circle_animation" r="40" cy="50" cx="50" />
        </g>
    </svg>
</div>

    The line height on the h2 must be half the height on the svg html tag.
    The circle-animation stroke-dasharray is the circumference of the circle (2 * pi * r).
    The stroke dash-offset is the 'opening' of the circle: 0 is full, value of stroke-dasharray is empty.

*/

.timer-container {
    text-align: center;

    > .timer-graphic {
        position: relative;
        height: 100px;
        width: 100px;
        margin: auto;

        > .timer-title {
            position: absolute;
            line-height: 59px;
            text-align: center;
            width: 100%;
            z-index: 1;
            color: white;

            > svg {
                fill: white;
                width: 40px;
                height: 40px;
                margin-top: 10px;
            }

            .fa {
                line-height: 56px;
            }
        }

        > .timer-svg {
            position: absolute;
            left: 50%;
            @include transform(translateX(-50%) rotate(-90deg));
        }
    }

    .timer-subtitle {
        opacity: 0;
        @include transition(opacity 0.25s);
        /*position: absolute;*/
        /*bottom: 0;
        right: 0;*/
    }
}





.circle_inside {
    stroke-dasharray: 189; /* this value is the pixel circumference of the circle */
    stroke-dashoffset: 0;
    transition: all 1s linear;
    stroke-width: 5;
    stroke: $brand-gray;
    fill: $brand-gray;
}

.circle_animation_bg {
    stroke-dasharray: 2 2; /* this value is the pixel circumference of the circle */
    stroke-dashoffset: 0;
    transition: all 1s linear;
    stroke-width: 5;
    stroke: $brand-gray-light;
    fill: none;
}

.circle_animation {
    stroke-dasharray: 251; /* this value is the pixel circumference of the circle */
    stroke-dashoffset: 251;
    transition: all 1s linear;
    stroke-width: 5;
    stroke: $brand-main;
    fill: none;
}
