﻿/// <reference path="../variables">
/// <reference path="../mixins">

//-------------------------------------------------------------------------------------------------
//-- carousel
//-------------------------------------------------------------------------------------------------
@font-face {
	font-family: 'slick';
	font-weight: normal;
	font-style: normal;
	src: url('../../../fonts/slick.eot');
}

.navigate-pub {
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
}

.navigate-content {
	display: flex;
	justify-content: center;
	position: relative;
}

.navigate {
	position: relative;

	&.padding-wrapper {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		padding: 0px;
	}

	$scroll-icon-font-size: 30px;

	li {
		text-decoration: none;

		.item-inner {
			.pub-content {
				margin: 0px 5px;
			}
		}
	}

	.desc-controlNav {
		ul {
			text-align: center;

			li {
				background-color: white;
				color: $brand-main;
				border: 1px solid $brand-main;
				padding: 3px;
				cursor: pointer;

				&.active {
					background-color: $brand-main;
					color: white;
					border: 1px solid $brand-gray;
				}
			}
		}
	}

	.navigate-left, .navigate-right {
		img {
			cursor: pointer;
			height: 170px;
			width: 20px;
			opacity: 0.5;
			font-size: 24px;
		}
	}
	/*.item-inner {
        min-height: 200px;
    }*/
	.imgPartenaire {
		display: flex;
		width: 200px;
		height: 200px;
		border: 1px solid #ccc;
		justify-content: center;
		align-items: center;

		&:hover {
			border: 2px solid #ccc;
			box-shadow: 10px 5px 5px #ccc;
		}

		img {
			margin-left: auto;
			margin-right: auto;
			//margin-top: 15px;
			//margin-bottom: 15px;
			width: 150px;
		}
	}

	.nivo-directionNav a {
		position: absolute;
		top: 45%;
		z-index: 9;
		cursor: pointer;
	}

	.nivo-controlNav {
		text-align: center;
		padding: 0;
		z-index: 9;
		text-align: center;
	}

	.nivo-controlNav a {
		//font-size: 0;
		position: relative;
		z-index: 9;
		display: inline-block;
		background: none;
		text-decoration: none;
	}

	.nivo-controlNav a:after {
		font-family: 'FontAwesome';
		font-size: 20px;
		color: lightgray;
		padding: 0 5px;
		cursor: pointer;
	}

	.nivo-controlNav a:after {
		content: "\f140";
	}

	.a.icon-qt-left:after {
		font-size: $scroll-icon-font-size;
		content: "\f104";
	}

	.a.icon-qt-right:after {
		font-size: $scroll-icon-font-size;
		content: "\f105";
	}

	.a.icon-arrow_round-left:after {
		font-size: $scroll-icon-font-size;
		content: "\f0a8";
	}

	.a.icon-arrow_round-right:after {
		font-size: $scroll-icon-font-size;
		content: "\f0a9";
	}

	.a.icon-arrow_double_round-left:after {
		font-size: $scroll-icon-font-size;
		content: "\f100";
	}

	.a.icon-arrow_double_round-right:after {
		font-size: $scroll-icon-font-size;
		content: "\f101";
	}

	.a.active:after {
		color: $brand-main;
	}
	/*____________________*/
	.navPrev, .navNext {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 20px;
		height: 20px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
		z-index: 9999;

		&:before {
			font-family: 'slick';
			font-size: 20px;
			font-weight: bold;
			line-height: 1;
			opacity: .75;
			color: $brand-main;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}

	.navPrev {
		left: -1px;

		&:before {
			content: url("/Areas/shared/static/img/chevron_left.svg");
		}
	}

	.navNext {
		right: -1px;

		&:before {
			content: url("/Areas/shared/static/img/chevron_right.svg");
		}
	}
}
