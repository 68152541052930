﻿.block-search-name-isin{
    background-color: $brand-main;
    padding: 8px 0;
}

.qt-search-isin{
    background-color: $brand-main;
    padding: 8px 0;
}

textarea.qt-search-input-typeahead {
    display: block;
    width: 100%;
    max-height: 250px;
    height: 32px;
    min-height: 32px;
    resize: vertical;
    padding: 6px 12px;
    background: #fff;
    /*border: 1px solid #ccc;
        border-radius: 2px 0 0 2px;
        transition: all ease-in-out .15s;*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
}