﻿/// <reference path="../variables">

@media (min-width: 768px) {
	.width-50-sm {
		width: 50px;
	}

	.width-100-sm {
		width: 100px;
	}

	.width-150-sm {
		width: 150px;
	}

	.width-200-sm {
		width: 200px;
	}
}


// Container of date-range-bar
.date-range-menu {
	padding: 10px;
	background-color: $brand-main;

	&.sticky-bar {
		z-index: 100000; //to do: move in variable
	}

	& .btn, & .form-control {
		height: 30px;
		display: inline-block;
		background-color: white;
		vertical-align: middle;
		font-weight: bold;
	}
}


.date-range-bar {
	display: inline-block;
	/*position: absolute;
    top: 50%;
    transform: translateY(-50%);*/
	& .popover {
		max-width: none;
	}

	& .range-dtpick {
		height: 264px;
	}

	& .form-control, & .btn {
		/* Clément: Non, on ne change pas la taille d'UN form-control comme ça */
		/*height: 34px;*/
		margin-right: 5px;
	}
}

