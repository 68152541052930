﻿/// <reference path= "../../Shared/Static/scss/bootstrap/mixins"/>
/// <reference path= "../../Shared/Static/scss/bootstrap/variables"/>
/// <reference path= "../../Shared/Static/scss/quantalys/mixins"/>
/// <reference path= "../../Shared/Static/scss/quantalys/variables"/>

/* Synthese */

$title-color-fonds: $brand-main !default;

h4 {
	color: $title-color-fonds;
}

// fiche d'id
$id-vl-font-size: $title-font-size * 1.4;
$id-vl-border-color: $brand-main;
$id-vl-color: $brand-main;
$id-date-bg: $brand-main;
$id-date-color: #fff;

.link-ajuste a {
	color: $brand-main;
	text-decoration: underline;
}

.text-icon-algo {
	font-size: 10px;
	vertical-align: super;
	font-weight: bold;

	&.default-color {
		color: rosybrown;
		text-shadow: 2px 1px 5px rgba(188, 143, 143, 0.63);
	}
}

.row-layout > [class*='col-'] {
	margin-bottom: 0px;
}

.inside-full-height, .panel-fixed-height {
	margin-bottom: 7px;
}

.produit-type {
	color: gray;
	font-size: 15px;
}

.layout-rating-3months {
	font-style: italic;
	color: $brand-main;
	font-size: $font-size-small;
}

.notation-appix {
	color: $brand-main;
	font-size: 12px;
	font-style: italic;
	margin-top: -5px;
}

.border-top {
	margin-top: 0px;
}

.btn-lg {
	padding: 13px 16px;
}

.vl-box {
	text-align: right;
	//on peut avoir
	// affichage une seule currency
	// <span class=vl-box-value> $VL_devise </span>
	// ou
	//affichage 2 currencies
	// <span class=vl-box-currencies>
	//		<div class=vl-box-devise-value> $VL_devise </div>
	//		<div class=vl-box-converted-value> $VL_converted </div>
	// </span>
	//du coup, séparer les bordures/padding de la font
	//bordure / padding
	.vl-box-currencies,
	.vl-box-value {
		display: inline-block;
		padding: 5px 10px;
		color: $id-vl-color;
		border: 1px solid $id-vl-border-color;
	}
	//fonts
	.vl-box-devise-value,
	.vl-box-value {
		font-size: $id-vl-font-size;
		font-weight: bold;
	}

	.vl-box-converted-value {
		font-size: 12px;
	}


	.vl-box-legend {
		font-weight: bold;
		color: $id-vl-color;
	}

	.vl-box-date {
		display: inline-block;
		color: $id-date-color;
		background: $id-date-bg;
		font-style: italic;
		padding: 4px 12px;
	}
}

.highlight {
	color: $title-color-fonds;
}

.no-wrap {
	white-space: nowrap;
}

.quartile-default {
	float: right;
	display: block;
	width: 15%;
	background: $brand-light;
	height: 10px;
	margin: 2px;
	min-width: 10px;
	max-width: 15px;

	&.quartile-1 {
		background: $brand-success;
	}

	&.quartile-2 {
		background: #7feb12;
	}

	&.quartile-3 {
		background: $brand-warning;
	}

	&.quartile-4 {
		background: $brand-danger;
	}
}

.indicateur {
	float: left;
	display: block;
	width: 20%;
	height: 6px;
	padding-left: 2px;
	min-width: 6px;
	background-clip: content-box;

	&:first-child {
		padding-left: 0;
	}

	&.indic-bg-left {
		background-color: #d0d0d0;
	}

	&.indic-bg-middle {
		background-color: $brand-main;
	}

	&.indic-bg-right {
		background-color: #a9d3ff;
	}
}

@mixin indic-selected-variant($position) {
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: -8px;
		width: 0;
		height: 0;
		border-color: black transparent transparent transparent;
		border-style: solid;
		border-width: 7px;
		left: $position;
	}
}

.indic-selected-1 {
	@include indic-selected-variant(5%);
}

.indic-selected-2 {
	@include indic-selected-variant(25%);
}

.indic-selected-3 {
	@include indic-selected-variant(45%);
}

.indic-selected-4 {
	@include indic-selected-variant(65%);
}

.indic-selected-5 {
	@include indic-selected-variant(85%);
}

/*Profil de Risque SRRI*/


.indic-srri {
	float: left;
	font-size: 15px;
	background: white;
	border: 1px solid $brand-main;
	color: $brand-main;
	text-align: center;
	font-weight: bold;

	$srri-count: 7;
	width: (100%/$srri-count);

	&:not(:first-child) {
		border-left: 0;
	}
}

.indic-srri-selected {
	color: white;
	background: $brand-main;
}

.indic-srri-clickable {
	cursor: pointer;
}

.indic-srri-small {
	font-size: 12px;
}

/* Performances */

.tab_title {
	color: #FFFFFF;
	background: #318CE7;
}


[data-error] {
	@include centerer(true, true);
	width: 100%;
}

.chartNuagePointSquare {
	position: relative;
	width: 250px;
	height: 239px;
	margin: auto;
	/*@media(min-width: 1020px) {
        margin-left: 5px;
    }*/
	@media(max-width: 300px) { // Or else the graph goes outside of the panel
		margin-left: -20px;
	}
}

.chartNuagePointSquareBig {
	position: relative;
	width: 500px;
	height: 500px;
	margin: auto;
}

@mixin chartheight($height) {
	position: relative;
	width: 100%;
	height: $height;
}

.chart-l {
	@include chartheight(400px);
}

.chart-l-no-legend {
	@include chartheight(300px);
}

.chart-m {
	@include chartheight(300px);
}

.chart-xm {
	@include chartheight(275px);
}

.chart-s {
	@include chartheight(160px);
}

.qtjs-chart-fvscat {
	position: relative;
	width: 300px;
	height: 300px;
	margin: auto;

	@media(max-width: 350px) { // Or else the graph goes outside of the panel
		margin-left: -20px;
		width: 250px;
		height: 250px;
	}
}

hr {
	display: block;
	position: relative;
	padding: 0;
	margin: 8px auto;
	height: 0;
	width: 100%;
	max-height: 0;
	font-size: 1px;
	line-height: 0;
	clear: both;
	border: none;
	border-top: 1px solid #aaaaaa;
	border-bottom: 1px solid #ffffff;
}

.rectangle-notation-compo {
	height: 15px;
	transform-origin: left;
	animation: createRectangleNotation 1s;
}

@keyframes createRectangleNotation {
	from {
		transform: scaleX(0);
	}

	to {
		transform: scaleX(1);
	}
}

.pill-compo-small {
	padding: 0px 12px !important;
}

.display-block-left {
	display: inline-block;
	float: left;
}

.display-block-right {
	display: inline-block;
	float: right;
}

.star-notation {
	position: absolute;
	right: 0;
}

.table-compo-header {
	padding: 4px 0px;
	color: $brand-minor;
	font-weight: bold;
}

.image-small {
	width: 25px;
	height: 25px;
}

.no-left-padding {
	padding-left: 0px !important;
}

.no-right-padding {
	padding-right: 0px !important;
}


.icon-small {
	/*zoom: 0.07;
    -moz-transform: scale(0.07);*/
	width: 15px;
}

.icon-med {
	@include transform(scale(0.7));
}

.color-brand-main {
	color: $brand-main;
}

.panel-body.reduced-padding {
	padding: 0 8px 5px 8px;
}

.panel-fixed-height {
	height: 367px;
}

.list-rehaussed {
	width: auto;

	@media (min-width: $screen-md) {
		margin-top: -6px;
	}
}

.modal {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.modal:before {
		display: inline-block;
		vertical-align: middle;
		content: " ";
		height: 100%;
	}
}

.modal-dialog {
	display: inline-block;
	text-align: left;
	vertical-align: middle;
}

.radio-favorite {
	position: relative !important;
	margin: 0px !important;
}

.custom-padding-h-split {
	padding-left: 0px;
	padding-right: 0px;
}

.full-height {
	height: 100%;
}

.circle-invest {
	float: left;
	background-color: $brand-main;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	margin-right: 5px;
	margin-top: 4px;
}

.color-brand-legend {
	background-color: $brand-main;
}

p.disclaimer {
	font-size: 12px; /*ceil($font-size-base * 0.7);*/
	text-align: justify;
}

.table-performances {
	> tbody {
		> tr {
			&.table-performances-header {
				border: none;
			}

			> td {
				&:nth-child(2),
				&:nth-child(3) {
					color: $text-color;
				}
			}

			> th {
				padding: 0;
			}
		}
	}
}

/*Bouton export amcharts*/
.amcharts-export-menu-top-right {
	top: -40px;
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	.btn-group-100 {
		width: 100%
	}

	.btn-group-100:first-child {
		margin-bottom: 1px
	}

	.btn-group-100 > .btn {
		width: 100%
	}
}

/* legende bas de tableau */
.legende-data {
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: x-small;
}

.synthese-indicateur {
	& .row-title {
		& .value {
			width: 50%;
			text-align: left;
		}

		& .rapport-cat {
			width: 50%;
			text-align: left;
		}
	}

	& .row-content {
		& .titre {
			width: 30%;
			padding-left: 0px;
			padding-right: 0px;
		}

		& .value {
			width: 20%;
			text-align: right;
			padding-left: 0px;
			padding-right: 0px;
		}

		& .notation {
			width: 25%;
			padding-left: 5px;
			padding-right: 0px;
		}

		& .estimation {
			width: 25%;
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

.row-layout > [class*='col-'] {
	margin-top: 0;
	margin-bottom: 0px;
}

.no-flexbox .panel.inside-full-height {
	height: auto;
	width: 100%;
}

.dl-horizontal.dl-horizontal-28-72 > dt {
	width: 28%;
}

.dl-horizontal {
	> dt {
		font-size: 12px;
	}
}



.link-ajuste {
	.default-link {
		color: #F9F9F9;
		text-decoration: none;
		//margin-left: 5px;
	}

	$structure-societe-width: 200px;
	$md-margin-bottom: 15px;

	.societe-wrapper {
		//text-align: center;
		.societe-content {
			padding-top: 10px;
			font-weight: bold;
		}

		.selected-rounded-rect {
			width: $structure-societe-width;
			margin-left: auto;
			margin-right: 0px;
			height: 75px;
			border: 2px solid gray;
			border-radius: 5px;
			/*@media (max-width: $screen-md) {
            margin-bottom: $md-margin-bottom;
        }*/
		}

		.societe-header {
			padding-top: 25px;
			color: #303F9F;
		}

		.societe-content {
			text-align: right;
		}
	}

	.btn-wrapper {
		.selected-rounded-rect:hover {
			width: 203px;
			border: 2px solid #303F9F;

			.societe-header {
				color: gray;
			}
		}
	}

	.btn {
		@mixin separator() {
			content: "";
			position: absolute;
			border: 1px solid white;
			height: 12px;
			width: 1px;
			top: 20%;
			//left: -10%;
		}

		.info-box {
			position: relative;
			color: white;
			font-size: 14px;

			&.info-separator {
				&:before {
					@include separator();
				}
			}

			.fa-info-circle {
				padding-left: 10px;
			}
		}

		&.btn-sm {
			padding: 0px 6px;
		}
	}
}

.esg-label {
	font-weight: 600;
	color: $brand-gray;
	text-align: center;
}

.preseclection-wrapper {
	font-size: 12px;

	.no-padding-left {
		padding-left: 0px;
	}

	.pub-data {
		text-align: center;
	}

	.pub-data-title {
		text-align: right;
	}

	.pub-data-wrapper {
		//padding: 5px 0px;
		text-align: right;

		img {
			width: 60px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.table {
		margin-bottom: 0px;
	}

	.table.table-y-padding-small > tbody > tr > td {
		padding-top: 0px;
		padding-bottom: 7px;
		padding: 5px;
	}

	.table.table-y-padding-small > tbody > tr > td.logo-wrapper {
		padding-bottom: 0px;
		padding-top: 0px;
	}

	.table.table-y-padding-small > tbody > tr > td.preselectionTitle-wrapper {
		margin-bottom: 0px;
		padding-bottom: 0px;
		padding-top: 0px;
	}

	.logo-wrapper {

		.partenairePrefix-wrapper {
			padding-left: 0px;
			margin-top: -5px;
		}

		.partenaireLogo-wrapper {
			margin-left: -70px;
		}
	}
}


/*_______________________[ Fiche FCPR ]*/

.fcpr_container {
	.table {
		margin-bottom: 0px;
	}
}




/*_______________________[ Pub ]*/
.pub-ciblee-container {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0px; //92px;
	z-index: 100;

	.pup-remove {
		position: absolute;
		left: 928px; //845px;
		z-index: 10;
		cursor: pointer;
		font-size: 18px;
		background-color: lightgray;
		color: cornflowerblue;
	}

	img {
		position: relative;
	}
}


/*____________________ fiche fond currency dropdown  */



.lstCurrencyData {
	padding-left: 5px;
	padding-right: 5px;
}

#dropDownFicheProduit {

	.dropdown-menu li a {
		padding: 3px 10px;
	}


	ul li a.default-link {
		color: white;
	}

	ul li a.default-link:hover {
		background-color: #004d99;
		color: white;
	}

	.dropdown-menu li a.btn {
		margin-top: 10px;
	}
}

.label-logo {
	img {
		height: 2em;
		margin-bottom: 10px;
	}

	:not(:last-child) {
		margin-right: 10px;
	}
}

.esg-tab {
	font-size: 12px;

	.global-isr {
		width: 40.6%;
	}

	.esg-block {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		img {
			height: 2em;
		}

		.esg-intensite {
			margin-top: 0.25em;
			height: 1.5em;
		}
	}

	.esg-title {
		margin-bottom: 3px;
	}

	.esg-label-sm {
		font-size: 11px;
		margin-top: -2px;
	}

	.highlight-value {
		color: $brand-main;
	}


	&.esg-tab-investir {
		@media only screen and (max-width: $screen-sm) {
			.esg-label-list {
				margin-right: initial;
			}

			justify-content: space-between;
		}

		@media only screen and (max-width: $screen-xs) {
			flex-direction: column;
		}

		@media only screen and (min-width: $screen-sm) {
			.esg-label-list {
				margin-right: 12% !important;
			}
		}

		> div:not(:last-child) {
			margin-right: 15px;
		}
	}
}
