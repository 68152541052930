﻿/// <reference path="../../bootstrap/variables">
/// <reference path="../../bootstrap//mixins">
/// <reference path="../variables">
/// <reference path="../mixins">

.heatmap {
	margin-top: 40px; //On décale le tableau pour avoir la place d'afficher la légende
	display: inline-block;
	width: 100%;
	aspect-ratio: 1;
	max-width: 400px;

	/* IE */
	@media screen and (-ms-high-contrast: none) and (max-width:$screen-sm-max), (-ms-high-contrast: active) and (max-width:$screen-sm-max) {
		width: 200px;
		height: 200px;
	}

	@media screen and (-ms-high-contrast: none) and (min-width:$screen-md-min), (-ms-high-contrast: active) and (min-width:$screen-md-min) {
		width: 400px;
		height: 400px;
	}

	/* Safari 10.1+ */
	@media not all and (min-resolution:.001dpcm) { 
		@supports (-webkit-appearance:none) {
			.safari_only { 
				width: 400px;
				height: 400px;
			}
		}
	}

	& .legend-heatmap {
		position: relative;
		width: 100%;

		& .volatil-top-left {
			position: absolute;
			transform: translate(0px, -100%);
		}

		& .volatil-top-right {
			position: absolute;
			right: 0px;
			transform: translate(0px, -100%);
		}

		& .volatil-bottom-left {
			position: absolute;
		}
	}
}

.echelle-heatmap {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	width: -moz-fit-content;
	width: fit-content;
	margin-top: 20px;

	@media screen and (max-width:$screen-xs-max) {
		margin-top: 30px;
	}

	/* IE */
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		width: 400px;
	}

	/* Safari 10.1+ */
	@media not all and (min-resolution:.001dpcm) { 
		@supports (-webkit-appearance:none) {
			.safari_only { 
				width: 400px;
			}
		}
	}

	.echelle-heatmap-legend {
		position: absolute;

		&.echelle-heatmap-legend-left {
			top: 5px;
			left: 0px;
		}

		&.echelle-heatmap-legend-right {
			top: 5px;
			right: 0px;
		}
		/* Ecran SM et au-dessus */
		@media screen and (min-width:$screen-sm-min) {
			&.echelle-heatmap-legend-left {
				transform: translate(-100%, 0px);
				padding-right: 7px;
			}

			&.echelle-heatmap-legend-right {
				transform: translate(100%, 0px);
				padding-left: 7px;
			}
		}
		/* Ecran XS */
		@media screen and (max-width:$screen-xs-max) {
			&.echelle-heatmap-legend-left {
				transform: translate(0px, -100%);
				padding-bottom: 5px;
			}

			&.echelle-heatmap-legend-right {
				transform: translate(0px, -100%);
				padding-bottom: 5px;
			}
		}
	}

	.echelle-heatmap-item {
		height: 30px;
		width: 10px;
		display: table-cell;
		position: relative;

		.echelle-heatmap-legend {
			position: absolute;
			bottom: -20px;
			right: 13%;
		}
	}
}


.legend-correlation {
	font-size: 11px;
}

.diversification-bon {
	color: #43A862;
}

.diversification-moyen {
	color: #FCBF37;
}

.diversification-mauvais {
	color: #D32C34;
}

.correlation-heat-map {
	width: 100%;
	height: 100%;

	& .correlation-item {
		display: inline-block;
		height: 100%;
		padding: 1px;
		float: left;

		& .item-height {
			height: 100%;
		}
	}
}
