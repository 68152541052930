﻿/// <reference path="../_variables.scss" />

.form-ventilated {
    & fieldset {
        margin-bottom: 20px;

        & legend {
            font-size: $font-size-legend;
            margin: 15px 0 10px;
            color: $brand-paint;
            font-weight: bold;
        }
    }
}
