﻿/// <reference path="../variables">
/// <reference path="../../bootstrap/variables">
/// <reference path="../mixins">

.conseil-default {
    float: left;
    display: block;
    width: 12%;
    background: $brand-light;
    height: 18px;
    margin-left: 2px;
    min-width: 7px;
    border-radius: 2px;

    &:first-child {
        margin-left: 0;
    }
}

.conseil-selected {
    background: $brand-main;

    &.conseil-green {
        background: $brand-green;
    }
}

.quartile-default {
    float: right;
    display: block;
    width: 15%;
    background: $brand-light;
    height: 12px;
    margin: 2px;
    min-width: $min-width-quartile;
    max-width: $max-width-quatile;

    &.quartile-1 {
        background: $brand-success;
    }

    &.quartile-2 {
        background: #7feb12;
    }

    &.quartile-3 {
        background: $brand-warning;
    }

    &.quartile-4 {
        background: $brand-danger;
    }

    &.quartile-main {
        background: $brand-main;
    }
}

@mixin indic-sirri-color-variant($bg-color, $color) {
    /*float: left;
    display: block;
    font-size: 15px;
    width: 13%;
    margin-left: 2px;
    max-width: 22px;
    */
    background: $bg-color;
    color: $color;

    &.indic-srri-selected {
        color: white;
        background: $brand-main;
    }
    /*&:first-child {
        margin-left: 0px;
    }*/
}

/*Profil de Risque SRRI*/
/*.indic-srri-default {
    @include indic-sirri-color-variant($brand-gray-darken, white);
}

.indic-srri-negatif {
    @include indic-sirri-color-variant(white, $brand-gray-darken);
    border: 1px solid rgba($brand-light, 0.8);
    box-shadow: 0 2px 3px $brand-gray-lightenen;
}*/



@mixin indic-srri-box-size-variant($srri-box-width, $srri-font-size) {
    width: $srri-box-width;

    &.indic-srri-default {
        .indic-srri {
            @include indic-sirri-color-variant($brand-gray-darken, white);
        }
    }

    &.indic-srri-negatif {
        .indic-srri {
            @include indic-sirri-color-variant(white, $brand-gray-darken);
        }
    }

    &.srri-border {
        .indic-srri {
            border: 1px solid rgba($brand-light, 0.8);
            box-shadow: 0 2px 3px $brand-gray-lighten;
        }
    }

    .indic-srri {
        float: left;
        display: block;
        font-size: $srri-font-size;
        width: 13%;
        margin-left: 2px;
        max-width: 22px;

        &:first-child {
            margin-left: 0px;
        }
    }
}

.indic-srri-box-single {
    @include indic-srri-box-size-variant(22px, 14px);
    margin-left: auto;
    margin-right: auto;

    .indic-srri {
        float: none;
        width: 22px;
    }
}

.indic-srri-box-xs {
    @include indic-srri-box-size-variant(134px, 12px);
}

.indic-srri-box-sm {
    @include indic-srri-box-size-variant(142px, 14px);
}

.indic-srri-box-md {
    @include indic-srri-box-size-variant(166px, 15px);
}

.indic-srri-box-lg {
    @include indic-srri-box-size-variant(194px, 18px);

    .indic-srri {
        max-width: 26px;
    }
}




/*Variante de l'indicateur*/
@mixin indic-selected-variant($position) {
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: -8px;
        width: 0;
        height: 0;
        border-color: black transparent transparent transparent;
        border-style: solid;
        border-width: 7px;
        left: $position;
    }
}

.indic-selected-1 {
    @include indic-selected-variant(5%);
}

.indic-selected-2 {
    @include indic-selected-variant(25%);
}

.indic-selected-3 {
    @include indic-selected-variant(45%);
}

.indic-selected-4 {
    @include indic-selected-variant(65%);
}

.indic-selected-5 {
    @include indic-selected-variant(85%);
}

@mixin rend-range-result-variant($best, $worst) {
    & .rend-worst-result {
        width: $worst;
        left: $best;
        right: 0%;
    }

    & .rend-best-result {
        width: $best;
        right: $worst;
        left: 0%;
    }
}

.rend-range-result-wrapper {
    width: 50%;
    margin: auto;
    margin-bottom: 15px;
}

.rend-range-result {
    position: relative;
    height: 12px;
    padding: 10px 0 20px;
    width: 100%;

    & .rend-worst-result {
        position: absolute;
        right: 50%;
        background: white;
        height: 17px;
        margin-left: 2px;
    }

    & .rend-best-result {
        position: absolute;
        left: 50%;
        background: $brand-main;
        height: 17px;
        margin-right: 2px;
    }

    &.rend-result-0-100 {
        @include rend-range-result-variant(0%, 100%);
    }

    &.rend-result-50-50 {
        @include rend-range-result-variant(50%, 50%);
    }
}
