﻿$arrow-size:5px;
$border-size:2px;
$arrow-border-size: $border-size + 1px; //Ratio logique que j'ai trouvé à force de tester

.quantatooltip {
    & .quantatooltip-inner {
        display: none;
        border: $border-size solid $brand-main;
        background-color: white;
        opacity: 1;
        padding: 8px 10px;
        text-align: center;
        transition: all 1s ease-out;
        font-size: 12px;

        &::after, &::before {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &::after {
            border-color: rgba(136, 183, 213, 0);
            border-width: $arrow-size;
        }

        &::before {
            border-color: rgba(194, 225, 245, 0);
            border-width: $arrow-size + $arrow-border-size;
        }


        &.tooltip-top {
            &::after, &::before {
                top: 100%;
                left: 50%;
            }

            &::after {
                border-top-color: #88b7d5;
                margin-left: -$arrow-size;
            }

            &::before {
                border-top-color: #c2e1f5;
                margin-left: -($arrow-size + $arrow-border-size);
            }
        }

        &.tooltip-bottom {
            &::after, &::before {
                bottom: 100%;
                left: 50%;
            }

            &::after {
                border-bottom-color: #88b7d5;
                margin-left: -$arrow-size;
            }

            &::before {
                border-bottom-color: #c2e1f5;
                margin-left: -($arrow-size + $arrow-border-size);
            }
        }

        &.tooltip-left {
            &::after, &::before {
                left: 100%;
                top: 50%;
            }

            &::after {
                border-left-color: #88b7d5;
                margin-top: -$arrow-size;
            }

            &::before {
                border-left-color: #c2e1f5;
                margin-top: -($arrow-size + $arrow-border-size);
            }
        }

        &.tooltip-right {
            &::after, &::before {
                right: 100%;
                top: 50%;
            }

            &::after {
                border-right-color: #88b7d5;
                margin-top: -$arrow-size;
            }

            &::before {
                border-right-color: #c2e1f5;
                margin-top: -($arrow-size + $arrow-border-size);
            }
        }

        &.active {
            display: block;
            position: absolute;
        }
    }
}
