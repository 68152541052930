﻿/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />
/// <reference path="../../bootstrap/mixins" />
/// <reference path="../../bootstrap/variables" />

.btn-main {
  @include button-variant($btn-main-color, $btn-main-bg, $btn-main-border);
}

.btn-minor {
  @include button-variant($btn-minor-color, $btn-minor-bg, $btn-minor-border);
}

.btn-paint {
  @include button-variant($btn-paint-color, $btn-paint-bg, $btn-paint-border);
}

.btn-dark {
    @include button-variant($btn-paint-color, $btn-paint-bg, $btn-paint-border);
}

.btn-clear-dark {
	@include button-variant($brand-dark, transparent, transparent);
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    border-color: $btn-disabled-border;
    background-color: $btn-disabled-bg;
    color: $btn-disabled-color;
    @include opacity(0.8);

    &:hover,
    &:focus,
    &.focus {
      background-color: $btn-disabled-bg;
          border-color: $btn-disabled-border;
    }
}

.btn-clear {
  @include button-variant(transparent, transparent, transparent);
}


.btn {
    & .glyphicon {
        top:2px;
        margin-left: 2px;
        margin-right: 2px;
    }

    &.btn-sm {
        
    }
}

.btn-chip {
    @include chip-size(24px, 12px, 0);
    padding: 0;

    &.btn-sm {
        @include chip-size(18px, 10px, 0);
        & .glyphicon {
            top: 1px;
            left: 0;
        }

        & .glyphicon-remove {
            top: 2px;

        }

        & .glyphicon-plus {
            top: 1px;
        }

        & .glyphicon-minus {
            top: 1px;
            left: -1px;
        }
    }

    & .glyphicon {
        margin: 0;
        top:3px;
    }
}

.btn-icon {
	background: transparent;
	border-color: transparent;
}

.btn-details{
    width: 13px;
    height: 13px;
    padding: 0;

    & .glyphicon {
        padding: 0;
        margin: 0;
        top: -4px;
        font-size: 9px;

        &.glyphicon-minus {
            padding: 0;
        }

        &.glyphicon-plus {
            padding: 2px;
        }
    }
}

//Nouveau style pour la popup ajouter de la comparaison, à réutiliser ailleurs ?
.btn-light{
    font-weight: bold;
    background-color: #fbfbfb;
}

//piqué d'IP, pck IP il est beau comme un camion
.btn-trash {
    height: 28px;
    width: 25px;
    padding: 0px !important;
    background-color: transparent;

    &:before {
        content: url('/Areas/Shared/Static/img/icon_trash.svg');
    }
}
