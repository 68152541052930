﻿/// <reference path="../variables">
/// <reference path="../../bootstrap/variables">
/// <reference path="../mixins">

.cell-background-fill {
    background-color:$brand-lighter;
}


/* CSS d'uniformisation des tableaux Quantalys */

.quantalys-table {
    /*display:table;*/
    display: block;
    width: 100%;

    &.quantalys-table-stripped {
        & > .quantalys-table-body {
            & > div.quantalys-table-row {
                &:nth-of-type(odd) {
                    background-color: $brand-light;
                }

                &:nth-of-type(even) {
                    background-color: white;
                }
            }

        }
    }

    &.quantalys-table-sm {
        & .quantalys-table-row {
            & > div {
                padding: 4px;

                &:first-child {
                    padding-left: 8px;
                }
            }
        }
    }

    & thead {
        & tr.head-title {
            color: #FFFFFF;
            background-color: $brand-main;
        }

        & tr {
            & td {
                vertical-align: middle;
            }
        }
    }

    & .title-colspan-table > td {
        text-align: center;
    }

    & .first-title-column {
        color: $brand-main;
    }

    & .center-cell {
        & td {
            vertical-align: middle;
        }
    }

    & .row-detail {

        & td {
            width: 80%;
        }
    }

    & .embedded-table {
        padding: 0;
    }

    .head-title {
        color: #FFFFFF;
        background-color: $brand-main;
    }
    /* CSS pour la création de table avec des Div */
    .quantalys-table-row {
        /*display:table-row;*/
        width: 100%;
        line-height: 20px;

        &:before, &:after {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }

        & .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            padding: 8px;
            font-size: 12px;
        }
    }

    & .title-colspan-table > div {
        text-align: center;
    }

    .quantalys-table-body {
        /*display:table-row-group;*/
        width: 100%;
    }

    .quantalys-table-header {
        /*display:table-header-group;*/
        width: 100%;
    }

    .quantalys-table-footer {
        /*display:table-footer-group;*/
        width: 100%;
    }

    & .quantalys-table-row, .quantalys-table-body, .quantalys-table-header, .quantalys-table-footer {
        clear: both;
    }

    & .title-cell {
        color: $brand-main;
    }

    & .sub-row {
        & .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            padding-top: 4px;
            padding-bottom: 4px;

            /*&:first-child {
                padding-left: 20px;
            }*/
        }
    }

    /* Tableau avec un design plus clair */
    &.quantalys-table-light {
        & .quantalys-table-header {
            & .quantalys-table-row {
                & > div {
                    border-right:1px solid $brand-gray-lighten;
                }
            }
        }

        & .quantalys-table-body {
            & .quantalys-table-row {
                & > div {
                    /*border-left:1px solid $brand-gray;*/
                    border-right:1px solid $brand-gray-lighten;

                    &:first-child {
                        border-left:none;
                    }
                }
            }
        }
    }
}



/* Tableau avec un design plus clair */
.quantalys-table-light {
    & thead {
        & tr {
            & > td {
                border-right:1px solid $brand-gray-lighten;
            }
        }
    }

    & tbody {
        & tr {
            & > td {
                /*border-left:1px solid $brand-gray;*/
                border-right:1px solid $brand-gray-lighten;

                &:first-child {
                    border-left:none;
                }
            }
        }
    }
}


.table-nowrap {
    & td, & th {
	    white-space: nowrap;
    }
}

.table-nowrap-th {
    & th {
	    white-space: nowrap;
    }
}

.table-nowrap-td {
    & td {
	    white-space: nowrap;
    }
}


.table-condensed-no-padding{
    & thead, & tbody{
            & tr th, & tr td{
            padding: 0;
        }
    }
}

.table-responsive {
    input.form-control:focus {
        @include box-shadow(none);
    }
}