﻿/// <reference path="../bootstrap/variables">
/// <reference path="../bootstrap//mixins">
/// <reference path="variables">
/// <reference path="mixins">

body {
    background-color: $brand-light;
}

/*.glyphicon {
	color: hsla(212, 83%, 33%, 1);
}*/

.glyphicon.clickable:hover {
    color: lighten($brand-paint, 33%);
}

.numPositif {
    color: #080 !important;
}

.numNegatif {
    color: red !important;
}

.input-inline {
    display: inline-block;
    width: auto;
}


.input-group {
    & .input-inline {
        display: inline-block;
        width: auto;
    }
}

label.lighter {
    font-weight: $font-weight-4;
}

.label-sm {
    padding-top: 7px;
    margin-bottom: 0;
}

.form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 14px;
}

.form-group-inline {
    margin-bottom: 15px;

    & input {
        display: inline-block;
        width: auto;
    }

    & label {
        padding-right: 10px;
    }
}

#cookie-banner {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(61,61,61,0.9);
    min-height: 100px;
    z-index: 10;

    p {
        padding: 25px 80px;
        font-size: 16px;
        text-align: center;
        color: white;

        a {
            cursor: pointer;
        }
    }
}

// simule un tag VR : vertical rule (voir tag HR)
.vr-l {
    border-left: 1px solid $brand-minor;
}

.vr-r {
    border-right: 1px solid $brand-minor;
}

@media (max-width: $screen-xs-max) {
    .vr-l.vr-hidden-xs {
        border-left: none;
    }

    .vr-r.vr-hidden-xs {
        border-right: none;
    }
}

@media (max-width: $screen-sm-max) {
    .vr-l.vr-hidden-sm {
        border-left: none;
    }

    .vr-r.vr-hidden-sm {
        border-right: none;
    }
}

@media (max-width: $screen-md-max) {
    .vr-l.vr-hidden-sm {
        border-left: none;
    }

    .vr-r.vr-hidden-sm {
        border-right: none;
    }
}


@mixin col-divider() {
    content: '';
    background-color: rgba($brand-gray-lighten, 0.6);
    position: absolute;
}

@mixin col-divider-vert($thickness) {
    @include col-divider();
    width: $thickness;
    height: 84%;
    top: 8%;
}

@mixin col-divider-hor($thickness) {
    @include col-divider();
    height: $thickness;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

@mixin col-divider-hor-bottom($thickness) {
    &:after {
        @include col-divider-hor($thickness);
        bottom: 0;
        top: auto;
    }

    padding-bottom: 10px;

    & + [class*='col-'] {
        padding-top: 10px;
    }
}

@mixin col-divider-hor-top($thickness) {
    &:before {
        @include col-divider-hor($thickness);
        top: 0;
    }

    padding-top: 10px;
}

@mixin col-divider-l($thickness: 1px) {
    &:before {
        @include col-divider-vert($thickness);
        left: 0;
    }

    &.divider-xs {
        @media(max-width: $screen-xs-min) {
            @include col-divider-hor-top($thickness);
        }
    }

    &.divider-sm {
        @media(max-width: $screen-sm-min) {
            @include col-divider-hor-top($thickness);
        }
    }

    &.divider-md {
        @media(max-width: $screen-md-min) {
            @include col-divider-hor-top($thickness);
        }
    }

    &.divider-lg {
        @media(max-width: $screen-lg-min) {
            @include col-divider-hor-top($thickness);
        }
    }
}

@mixin col-divider-r($thickness: 1px) {
    &:after {
        @include col-divider-vert($thickness);
        right: 0;
    }

    &.divider-xs {
        @media(max-width: $screen-xs-min) {
            @include col-divider-hor-bottom($thickness);
        }
    }

    &.divider-sm {
        @media(max-width: $screen-sm-min) {
            @include col-divider-hor-bottom($thickness);
        }
    }

    &.divider-md {
        @media(max-width: $screen-md-min) {
            @include col-divider-hor-bottom($thickness);
        }
    }

    &.divider-lg {
        @media(max-width: $screen-lg-min) {
            @include col-divider-hor-bottom($thickness);
        }
    }
}


/* Divider vertical entre deux colonnes bootstrap */
/* avec .divider-lg, -md, -sm, -xs il passe en horizontal 
    selon la taille d'écran indiqué */
.col-divider-l {
    @include col-divider-l();

    &.divider-strong {
        @include col-divider-l(2px);
    }
}

.col-divider-r {
    @include col-divider-r();

    &.divider-strong {
        @include col-divider-r(2px);
    }
}

/* Override des tooltips Bootstrap */

.tooltip-inner {
    max-width: 300px;
    width: 300px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
    color: #444;
    border: 1px solid $brand-main;
    padding: 5px 8px;
}

/* ---- Michou's legend ! ---- */

.icon-remove-legend {
    font-size: 15px;
    vertical-align: top;
    padding-top: 2px;
}

/* Légende de graph avec un fond blanc */
.templateLegendeChartBarre {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    padding: 5px 10px;
    margin-top: 5px;

    & .legendeItemName {
        font-size: 14px;
    }
}

/* Légende de graph avec un fond gris */
.templateLegendeItemChart {
    display: inline-block;
    background-color: #F6F6F6;
    margin-right: 15px;
    vertical-align: middle;
    padding: 2px 7px;
    margin-top: 5px;
}

/* Légende de graph avec de petites marges et un fond blanc */
.templateLegendeConcat {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    padding: 2px 4px;
    margin-top: 5px;

    & .legendeItemName {
        margin-left: 4px;
        margin-right: 4px;
        font-size: 12px;
    }
}

/* Item barre horizontal (ex : .legendeItemColor) */
.legendeItemBarreHorizontal {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 3px;
}

.legendeItemCircle {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;
    vertical-align: middle;
}

.legendeItemSquare {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: gray;
}

.legendeItemName {
    display: inline-block;
    margin: 0 6px;
    font-size: 12px;
    color: #547EB7;
}


.clickable {
    @include clickable();
}

.icon-right {
    float: right;
}

//Objectif: faire tourner l'indicateur de panel collapsible

//Cas 1 : indicateur graphique à l'intérieur du trigger
[data-toggle='collapse'] {
    @include clickable();

    & .icon-toggle-collapse {
        @include transition-transform(0.35s);
    }

    &.collapsed .icon-toggle-collapse {
        &.icon-right {
            @include rotate(90deg);
        }

        &.icon-left {
            @include rotate(-90deg);
        }
    }
}

//Cas 2 : indicateur graphique = trigger
.icon-toggle-collapse[data-toggle='collapse'] {
    @include clickable();
    @include transition-transform(0.35s);

    &.icon-right.collapsed {
        @include rotate(90deg);
    }

    &.icon-left.collapsed {
        @include rotate(-90deg);
    }
}

.container {
    min-width: $container-min-width !important;
}

/* Flex */

//Duplication avec la classe is-flex de grid.scss
.row.flex {
    @include flexbox();
    flex-wrap: wrap;

    &:after, &:before {
        display: block;
    }

    &.flex-center {
        align-items: center;
    }

    > [class*='col-'] {
        @include flexbox();
        flex-direction: column;
    }
}
.is-flex {
    .vcenter {
        justify-content: center;
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
}
/* remplacer le flex (ne fonctionne pas avec les produit apple en responsive) */

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.space-around {
	justify-content: space-around !important;
}

.space-between {
	justify-content: space-between !important;
}

.flex-row-left {
    margin-bottom: 8px;

    @media(min-width: $screen-md) {
        padding-right: 8px;
    }
}

.flex-row-center {
    margin-bottom: 8px;

    @media(min-width: $screen-md) {
        padding-right: 8px;
        padding-left: 0px;
    }

    @media(min-width: $screen-xs) and (max-width: $screen-md) {
        //padding-right: 8px;
        padding-left: 8px;
    }
}

.flex-row-right {
    margin-bottom: 8px;

    @media(min-width: $screen-md) {
        padding-left: 0px;
    }
}



/* Gestion des droits des Partenaire */

.disabled {
    color: $brand-minor !important;

    &.no-link {
        pointer-events: none;
        cursor: default;
    }
}

text-align-left {
	text-align: left;
}

text-align-right {
	text-align: right;
}

text-align-center {
	text-align: center;
}

/* Custom errors */
.http-error {
    padding: 30px;
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    color: #4a4949;
}

.http-error-details {
}

.final-user-msg {
    padding-bottom: 30px;
}

.http-error-callstack {
    padding-top: 30px;
    font-size: 12px;
    font-family: MONOSPACE;
}

.field-validation-error {
    color: $btn-danger-bg;
}

.form-horizontal {
    .radio-list-inline {
        padding-top: 6px;
    }
}


label.required-model {
    &:after {
        content: "*";
    }
}

.sticky{
    position:sticky;
    top:0px;
}

/* Input type='file' */
.input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label{
        cursor: pointer; /* "hand" cursor */
    }
    & + label * {
        pointer-events: none;
    }
    &:focus + label{
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
}

table.fixedHeader-floating {
    position: fixed !important;
    background-color: white;
    margin-top : 0px !important;
}

table.fixedHeader-floating.no-footer {
    border-bottom-width: 0
}

table.fixedHeader-locked {
    position: absolute !important;
    background-color: white
}

@media print {
    table.fixedHeader-floating {
        display: none
    }
}

.nav-tabs.nav-justified > li > a, .nav-tabs.nav-justified > li.active > a {
    &:hover {
        border: none;
    }

    border: none;
}

.disclaimer {
    margin: 10px auto;
    padding: 10px;
    text-align: center;
    border: 1px solid rgba($brand-gray-light, 0.7);
    font-size: 12px;

    p {
        font-size: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.h-inherit{
    height: inherit;
}

/* A applique sur une div englobant une table pour la rendre "responsive" */
.scrollable-table-wrapper {
	overflow: auto;

	/* -webkit-overflow-scrolling: touch --> active le scroll avec "momentum" (only safari), càd qu'on peut "continuer à scroller" une fois la fin atteinte  */
	-webkit-overflow-scrolling: touch;

	/* ! Sans le code ci-dessous le contenu n'est pas srollable sur safari mobile ! 
		Solution : https://stackoverflow.com/questions/23083462/how-to-get-an-iframe-to-be-responsive-in-ios-safari
	*/
	width: 1px;
	min-width: 100%;

	/*  en oveflow auto, safari mobile n'arrive pas à se décider et clignote
		Solution :
		overflow-x: scroll;
		overflow-y: visible;
		Sauf que ça affiche la scroll même quand pas besoin c'est moche, tant pis pour le clignotement c'est "léger"
		(https://stackoverflow.com/questions/47183749/ios-safari-flicker-when-scrolling)
	*/
}

//---------qt-star-container
.qt-star-container {
	display: inline-block;
}

.qt-star-container .small {
	width: 15px;
	height: 15px;
}

.qt-star-container .medium {
	width: 20px;
	height: 20px;
}

.qt-star-container .large {
	width: 30px;
	height: 30px;
}

.qt-star-container.clickable {
	cursor: pointer;
}

.qt-star-container > div {
	display: inline-block;
}

.qt-star-container > div.star-default {
	background: url(/Areas/Shared/Static/img/quantastar/quantalys_dark_gray_star.png) no-repeat top left;
	background-size: contain;
}

.qt-star-container > div.star-blue {
	background: url(/Areas/Shared/Static/img/quantastar/quantalys_blue_star.png) no-repeat top left;
	background-size: contain;
}
//-------------
.flex.row {
	.panel {
		height: 100%;
	}
}

// ----- Tarteaucitron
.youtube_player, .spotify_player{
	> iframe{
		border: 0;
	}
}
// -----
