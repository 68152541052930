﻿$arrow-size: 7px;

.popper {
	//on copie sur le css de bootstrap.popover qui est plutôt joli
	max-width: 276px;
	z-index: 1060;
	position: absolute;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-break: auto;
	line-height: 1.42857;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	font-size: 12px;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

	//to do : répliquer l'arrow de bootstrap.popover, là c'est moche
	.popper-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: $arrow-size;
		border-color: rgb(191, 191, 191);
	}

	.popper-inner {
		padding: 9px 14px;
	}

	&[x-placement^="top"] {
		margin-bottom: $arrow-size;

		.popper-arrow {
			border-width: $arrow-size $arrow-size 0 $arrow-size;
			border-left-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			bottom: -$arrow-size;
			left: calc(50% - $arrow-size );
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="bottom"] {
		margin-top: $arrow-size;

		.popper-arrow {
			border-width: 0 $arrow-size $arrow-size $arrow-size;
			border-left-color: transparent;
			border-right-color: transparent;
			border-top-color: transparent;
			top: -$arrow-size;
			left: calc(50% - $arrow-size );
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="right"] {
		margin-left: $arrow-size;

		.popper-arrow {
			border-width: $arrow-size $arrow-size $arrow-size 0;
			border-left-color: transparent;
			border-top-color: transparent;
			border-bottom-color: transparent;
			left: -$arrow-size;
			top: calc(50% - $arrow-size );
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^="left"] {
		margin-right: $arrow-size;

		.popper-arrow {
			border-width: $arrow-size 0 $arrow-size $arrow-size;
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			right: -$arrow-size;
			top: calc(50% - $arrow-size );
			margin-left: 0;
			margin-right: 0;
		}
	}
}








