﻿
@mixin chip-variant($color, $bg-color, $border-color) {
    color: $color;
    background-color: $bg-color;
    border-color: $border-color;
}

@mixin chip-size($size, $font-size, $pd-top) {
    height: $size;
    width: $size;
    border-radius: $size;
    font-size: $font-size;
    padding-top: $pd-top;
}
