﻿// <reference path="../..//bootstrap/variables">
/// <reference path="../../bootstrap//mixins">
/// <reference path="../variables">
/// <reference path="../mixins">


// Width

.w-100 {
	width: 100% !important;
}

// Margin and Padding

.m-x-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

@each $prop, $abbrev in (margin: m, padding: p) {
	@each $size, $lengths in $spacers {
		$length-x: map-get($lengths, x);
		$length-y: map-get($lengths, y);


		.#{$abbrev}-a-#{$size} {
			#{$prop}: $length-y $length-x !important;
		}
		// a = All sides
		.#{$abbrev}-t-#{$size} {
			#{$prop}-top: $length-y !important;
		}

		.#{$abbrev}-r-#{$size} {
			#{$prop}-right: $length-x !important;
		}

		.#{$abbrev}-b-#{$size} {
			#{$prop}-bottom: $length-y !important;
		}

		.#{$abbrev}-l-#{$size} {
			#{$prop}-left: $length-x !important;
		}

		.#{$abbrev}-dyn-indent-#{$size} {
			#{$prop}-left: #{$size}vw;
		}

		// Axes
		.#{$abbrev}-x-#{$size} {
			#{$prop}-right: $length-x !important;
			#{$prop}-left: $length-x !important;
		}

		.#{$abbrev}-y-#{$size} {
			#{$prop}-top: $length-y !important;
			#{$prop}-bottom: $length-y !important;
		}
	}
}

// margin et padding pour chaque media query
// attention, syntaxe differente de bootstrap4
// m-a-sm-1 = margin all 1space pour sm+		(bootstrap : m-sm-1)
// m-r-sm-1 = margin right 1space pour sm+      (bootsrap: mr-sm-1)
// m-r-sm-n1 = margin right -1 space pour sm+
//infix = abbreviation du breakpoint (sm,md, etc..)
@each $infix, $breakpoint-min in $grid-breakpoints {
	@media (min-width: $breakpoint-min) {
		@each $prop, $abbrev in (margin: m, padding: p) {
			@each $size, $lengths in $spacers {
				$length-x: map-get($lengths, x);
				$length-y: map-get($lengths, y);



				.#{$abbrev}-a-#{$infix}-#{$size} {
					#{$prop}: $length-x $length-y !important;
				}

				.#{$abbrev}-t-#{$infix}-#{$size},
				.#{$abbrev}-y-#{$infix}-#{$size} {
					#{$prop}-top: $length-y !important;
				}

				.#{$abbrev}-r-#{$infix}-#{$size},
				.#{$abbrev}-x-#{$infix}-#{$size} {
					#{$prop}-right: $length-x !important;
				}

				.#{$abbrev}-b-#{$infix}-#{$size},
				.#{$abbrev}-y-#{$infix}-#{$size} {
					#{$prop}-bottom: $length-y !important;
				}

				.#{$abbrev}-l-#{$infix}-#{$size},
				.#{$abbrev}-x-#{$infix}-#{$size} {
					#{$prop}-left: $length-x !important;
				}
			}
			//end each spacer

		}
		//end each prop abbrev
	}
	//end media

}
//end each breakpoint

.no-padding {
	& > [class*='col-'] {
		padding-left: 0;
		padding-right: 0;
	}
}

.dynamic-indent-1 {
	margin-left: 1vw
}
