﻿/// <reference path="../_variables.scss" />

.input-group-quantalys {

    .input-group-after {
        border-right: none;
    }

    & > .input-group-addon:first-child {
        border-right: none;
        background-color: white;
    }

    & > .input-group-addon:last-child {
        border-left: none;
        background-color: white;
    }

    & .form-control:not(:last-child) {
        border-right: none;
    }

    & .form-control:not(:first-child) {
        border-left: none;
    }
}

.input-w-xs,
.input-w-sm,
.input-w-md,
.input-w-lg {
    max-width: 100%;
}

.input-w-xs {
    width: 50px;
}

.input-w-sm {
    width: 80px;
}

.input-w-md {
    width: 120px;
}

.input-w-lg {
    width: 160px;
}

.form-ventilated {
    & fieldset {
        margin-bottom: 20px;

        & legend {
            font-size: $font-size-legend;
            margin: 15px 0 10px;
            color: $brand-paint;
            font-weight: bold;
        }
    }
}

.form-control {
    border: solid 1px $input-border;
    @include form-control-focus($input-focus-glow);
}