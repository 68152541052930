﻿/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />
/// <reference path="../../bootstrap/mixins" />
/// <reference path="../../bootstrap/variables" />
.search{

}
.pill {
	display: inline-block;
	position: relative;
	padding: 3px 0px 3px 10px;
	border-radius: 15px;
}
.pill-main {
	color: #0066CC;
	background-color: white;
	border: 1px solid;
	& button {
		opacity: 1;

		&:hover {
			opacity: 1;
			color: #003366;
		}
	}
	&:hover {
		color: #003366;
	}
	& .fa.fa-times {
		color: #0066CC;

		&:hover {
			color: #003366;
		}
	}
}
