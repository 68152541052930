﻿$table-pagination-margin: 5px;
$portfolio-overview-table-margin: 15px;
$portfolio-overview-padding-top: 10px;
$color-custom-table-footer: #f9f9f9;
$color-custom-table: #F2F2F2;
$color-quantalys-blue: #0066CC;
$color-override-h5: black;
$color-portfolio-detail-panel: white;

#portfolio-quantalys-list_paginate {
	margin: $table-pagination-margin;
}

#portfolio-quantalys-list_wrapper {
	margin: $portfolio-overview-table-margin;
	background-color: $color-custom-table;
}

h1 {
	color: $color-quantalys-blue;
}

.color-overrride-h5 {
	color: $color-override-h5;
}

.article-text-positioning {
	vertical-align: top;
}

.datatablefooter {
	background-color: $color-custom-table-footer;
}

#portfolio-quantalys-list_length {
	margin-right: 5px;
	margin-top: 5px;
}

#portfolio-overview-block {
	background-color: $color-portfolio-detail-panel;
}
