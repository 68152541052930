﻿/// <reference path="../../bootstrap/mixins">
/// <reference path="../../bootstrap/variables">

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    @include translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    @include translate(0, -50%);
  }
}


@media (max-width: $screen-xs-max) {
	.text-center-xs {
		text-align: center;		
	}	
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	.text-center-sm {
		text-align: center;
	}	
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	.text-center-md {
		text-align: center;
	}	
}

@media (min-width: $screen-lg-min) {
	.text-center-lg {
		text-align: center;
	}	
}

@mixin clickable($select: true) {
    @if($select) {
        @include user-select(none);
    }
    cursor: pointer;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
     -moz-transform: $transform;
      -ms-transform: $transform;
       -o-transform: $transform;
          transform: $transform;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @-moz-keyframes #{$name} { @content; }
  @-ms-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; } 
}
