﻿/// <reference path="../variables">
/// <reference path="../mixins">

$margin-panel-layout: 3px;

.fancy-panel-group {
	position: relative;
	padding: 2px;
	margin: 0 -2px 20px -2px;

	& .fancy-panel {
		border: 1px solid #d0d0d0;
		border-top-style: none;
		border-bottom-style: none;
		margin: 5px 0;
		padding: 15px;
		background: #fff;

		&:first-child,
		&.fancy-panel-first {
			border-top-style: solid;
			margin-top: 0;
		}

		&:last-child,
		&.fancy-panel-last {
			border-bottom-style: solid;
			margin-bottom: 0;
		}
	}

	& .fancy-panel-subgroup {
		+ .fancy-panel-subgroup {
			margin-top: 10px;
		}
	}

	&:before {
		position: absolute;
		content: "";
		@include block-corners($fancy-panel-corner-size);
		border-right-width: 0;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}

	&:after {
		position: absolute;
		content: "";
		@include block-corners($fancy-panel-corner-size);
		border-left-width: 0;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.panel {
	margin-bottom: 10px;
}

.panel-default {
	> .panel-heading {
		background-image: none;
		color: $panel-heading-color;
		background-color: $panel-background-color;
		border-color: $panel-background-color;
	}

	border-color: $panel-default-border;
}

.panel-main {
	@include panel-colored($brand-main, #F4F4F4, $panel-color-text);
}

.panel.panel-inside {
	/*border-color: $brand-light;*/
	margin-left: -$panel-body-padding;
	margin-right: -$panel-body-padding;

	border: none;

	> .panel-heading {
		border: none;
	}
}

.panel-heading {
	/*padding-top: 15px;
	padding-right: 15px;
	padding-left: 15px;
	padding-bottom: 0;*/
	padding: 15px;
	border-bottom: 1px solid transparent;
	border-radius: 0;

	& h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}
}

.panel-body {
	padding: 0 $panel-body-padding $panel-body-padding $panel-body-padding;
	/*> .table:first-of-type > thead > tr > th {
        padding-top: 0;
    }*/
}

.cancel-panel-padding {
	margin-left: -$panel-body-padding;
	margin-right: -$panel-body-padding;
}

.panel-condensed-layout {
	margin-top: $margin-panel-layout !important;
	margin-bottom: $margin-panel-layout !important;
}

.row-layout {
	> [class*='col-'] {
		margin-top: 0; /*$margin-panel-layout;*/
		margin-bottom: $margin-panel-layout;
	}
}


.panel-no-padding {
	padding: 0;

	& > .panel-body {
		padding: 0;
	}

	& > .panel-heading {
		padding: 15px;
	}
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
	.col-md-4.col-sm-3.col-xs-12.col-sm-offset-3.col-md-offset-2.text-center, .col-md-3.col-sm-3.col-xs-12.text-center {
		> .btn-group {
			width: 100%;

			&:first-child > .btn {
				margin-bottom: 1px;
			}

			> .btn {
				width: 100%;
			}
		}
	}
}

.panel-flex {
	display: flex;
	flex-direction: column;

	.panel-body-full-height {
		flex-grow: 1;
	}
}

.panel-width-auto {
	display: inline-block;
}
