﻿/// <reference path="../variables">
/// <reference path="../../bootstrap/variables">
/// <reference path="../mixins">

@mixin dl-horizontal-responsive-variant($breakpoint) {
	@media (max-width: $breakpoint) {
		& > dt, & > dd {
			clear: both;
			display: block;
			width: 100%;
			padding-right: 0;
		}

		& > dd {
			text-align: left;
			padding-left: 0;
			margin-left: 0;
		}

		&.dl-expand {
			dt {
				margin-bottom: 0;
			}
		}

		& dt + dd {
			margin-bottom: 5px;
		}

		&.dl-separate {
			dd {
				text-align: left;
			}
		}
	}
}

.dl-vertical {
	> dd {
		font-weight: bold;
		color: $brand-main;
	}

	> dt + dd {
		margin-bottom: 15px;
	}
}

.dl-small-font {
	font-size: 12px;
}

.dl-strong-value {
	> dd {
		font-weight: bold !important;
	}
}

.dl-strong-key {
	> dt {
		font-weight: bold !important;
	}
}

.dl-strong {
	@extend .dl-strong-key;
	@extend .dl-strong-value;
}

.dl-highlight-value {
	& > dd {
		color: $brand-main;
	}
}


.dl-horizontal {
	display: block;
	width: 100%;
	/*
        Sorry whoever, but fuck this
    */
	/*margin-top: -6px; // little fix to avoid the dl to go too far below, not really beautiful in css for still an effective global solution*/
	&:last-of-type {
		margin-bottom: 0
	}

	&.dl-horizontal-justify {
		text-align: justify;
	}

	> dt {
		overflow: hidden;
		float: left;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: normal;
		// padding-right: 15px; => We prefer to have as much space available for the text as possible, better have a readable text than nothing
		padding-right: 0;
	}

	> dd {
		float: none;
		padding-left: 15px;

		&.strong-value {
			font-weight: bold;
			color: $brand-main;
		}


		@include clearfix();
	}


	&.dl-horizontal-25-75 {
		& > dt {
			width: 25%;
		}

		& > dd {
			width: 75%;
			margin-left: 25%;
		}
	}

	&.dl-fichier-identite {
		& > dt {
			width: 27%;
		}

		& > dd {
			width: 73%;
			margin-left: 25%;
		}
	}

	&.dl-horizontal-33-67 {
		& > dt {
			width: 33%;
		}

		& > dd {
			width: 67%;
			margin-left: 33%;
		}
	}

	&.dl-horizontal-40-60 {
		& > dt {
			width: 40%;
		}

		& > dd {
			width: 60%;
			margin-left: 40%;
		}
	}

	&.dl-horizontal-50-50 {
		& > dt {
			width: 50%;
		}

		& > dd {
			width: 50%;
			margin-left: 50%;
		}
	}

	&.dl-horizontal-55-45 {
		& > dt {
			width: 55%;
		}

		& > dd {
			width: 45%;
			margin-left: 55%;
		}
	}

	&.dl-horizontal-60-40 {
		& > dt {
			width: 60%;
		}

		& > dd {
			width: 40%;
			margin-left: 60%;
		}
	}

	&.dl-horizontal-75-25 {
		& > dt {
			width: 75%;
		}

		& > dd {
			width: 25%;
			margin-left: 75%;
		}
	}

	&.dl-horizontal-sm {
		@include dl-horizontal-responsive-variant($screen-xs-max);
	}

	&.dl-horizontal-md {
		@include dl-horizontal-responsive-variant($screen-sm-max);
	}

	&.dl-horizontal-lg {
		@include dl-horizontal-responsive-variant($screen-md-max);
	}
	/* Une liste avec les valeur dd à droite de la width, et les dt à gauche*/
	&.dl-separate {
		& > dd {
			text-align: right;
		}

		& > dt {
			text-align: left;
		}
	}
	/* Gestion des padding */
	&.dl-padding-small {

		> dd, > dt {
			padding-top: 2px;
			padding-bottom: 2px;
		}
	}

	&.dl-padding-medium {
		> dd, > dt {
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}

	&.dl-padding-large {
		> dd, > dt {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	&.dl-expand {
		> dd, > dt {
			margin-bottom: 15px;
		}
	}

	&.dl-fluid {
		display: inline-block;

		> dd {
			float: right;
		}
	}
}

.striped-list {
	> * {
		padding: 15px;

		&:nth-of-type(even) {
			background-color: $brand-lighter;
		}

		&:nth-of-type(odd) {
			background-color: $brand-light;
		}
	}
}



ul.fancy-ul {
	list-style: none;
	padding: 0;

	> li {
		padding-left: 2em;
	}

	> li:before {
		content: "\f111";
		color: $brand-main;
		font-family: "Font Awesome 5 Pro";
		display: inline-block;
		margin-left: -2em;
		width: 2em;
		font-size: 6px;
		vertical-align: middle;
	}
}
