﻿/// <reference path="../variables">
/// <reference path="../mixins">

.graph-barre-horiz-container {
    width: 100%;

    & td, & th {
        padding: 4px;
    }

    & tfoot th {
        border-top: 1px solid $brand-main;
    }

    & .graph-barre-element {
        text-align: right;
        padding-right: 4px;
        margin-right: 10px;
        float: left;
        height: 20px;
    }

    & .graph-barre-legend {
        color: $brand-main;
        font-weight: bold;
        font-size: 12px;
        text-align: right;
        width: 60px;

        &.text-danger {
            color: $state-danger-text;
        }

        &.text-warning {
            color: $state-danger-text;
        }
    }

    & .graph-barre-element-label {
        color: $brand-main;
        font-weight: bold;
        font-size: 12px;
    }

    & .graph-barre-element-label-negative {
        color: #fff;
        /*text-shadow: rgba(#000, 0.6) 1px 1px 2px;*/
        /*text-shadow: rgba(#000, 0.8) 1px 1px 0, rgba(#000, 0.8) -1px -1px 0, rgba(#000, 0.8) 1px -1px 0, rgba(#000, 0.8) 0 1px 0, rgba(#000, 0.8) 1px 0 0, rgba(#000, 0.8) 0 -1px 0, rgba(#000, 0.8) -1px 0 0, rgba(#000, 0.8) -1px 1px 0;
        filter: progid:DXImageTransform.Microsoft.Glow(Color=#000000,Strength=1); //Contour pour IE < 10*/
        font-weight: bold;
        font-size: 12px;
        text-align: right;
    }
}



@mixin graph-percent-variant($color) {
    background-color: $color;
}

.graph-percent {
    width: 100%;
    height: 14px;
    position: relative;
    //overflow: hidden; // Kaddie: Obligé d'enlever cette ligne pour un graph qui peut dépasser valeur de 100%, je ne sais plus pourquoi c'était là à l'origine. Doit pas être bien grave
    background-color: transparent;

    &.graph-percent-minor {
        background-color: $brand-minor;
    }

    & .graph-percent-bar {
        height: 100%;
        float: left;

        &.graph-percent-bar-main {
            @include graph-percent-variant($brand-main);
        }

        &.graph-percent-bar-paint {
            @include graph-percent-variant($brand-paint);
        }

        &.graph-percent-bar-success {
            @include graph-percent-variant($brand-success);
        }

        &.graph-percent-bar-danger {
            @include graph-percent-variant($brand-danger);
        }
    }

    &.graph-percent-limit {
        & .graph-percent-bar {
            position: relative;

            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 0;
                right: -3px;
                height: 100%;
                width: 3px;
                background-color: #fff;
            }
        }
    }

    &.graph-percent-spaced {
        & .graph-percent-bar {
            padding-right: 4px;
            background-clip: content-box;
        }
    }

    &.graph-percent-bordered {
        border: 1px solid $brand-minor;
    }

    &.graph-percent-sm {
        height: 6px;
    }

    &.graph-percent-lg {
        height: 19px;
    }

    & .text-inside {
        text-align: center;
        font-weight: bold;
        color: white;
    }

    & .graph-addon {
        position: absolute;
        right: -60px;
        &.graph-addon-inside {
            right: 5px;
        }
    }
    & .graph-percent-value {
        position:absolute;
        color:black;
        padding-left:4px;
    }
}

.graph-inline {

    &.graph-text {
        padding-top: 8px;
    }

    &.graph-right {
        & .graph-element {
            float: right;
        }
    }

    &.graph-cancel-padding {
        padding-right: 0px;
        padding-left: 0px;
    }

    & .graph-addon {

        & .graph-arrow {
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8.7px 5px 0 5px;
            border-color: $brand-main transparent transparent transparent;
            top: 0;
            left: 10px;
        }

        & .graph-vertical-element {
            position: absolute;
            width: 3px;
            height: $graph-bar-small-height + $graph-bar-vertical-barre-height;
            background-color: black;
            top: $graph-bar-vertical-barre-height / 3;
        }
    }

    & .graph-element {
        display: inline-block;
        float: left;
        padding-left: 2px;
        padding-right: 2px;
        text-align: center;

        &.element-collapse {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    & .graph-inline-right {
        text-align: right;

        & .graph-element {
            float: none;
        }
    }
}

/* Hauteur des graph horizontaux */
.small-height {
    height: $graph-bar-small-height;
}

.medium-height {
    height: $graph-bar-medium-height;
}

.draw-rect {

    & .rect-notation {
    }
}
