@import "../../../../Shared/Static/scss/quantalys.scss";

//Variables & utilities
@import "../../../../Shared/Static/scss/bootstrap/_variables.scss";
@import "_variables_v2.scss";

@import "../../../../Shared/Static/scss/bootstrap/mixins";
@import "../../../../Shared/Static/scss/bootstrap/_pagination.scss";

//Modules Quantalys
@import "../../../../Shared/Static/scss/_site.scss";


.indic-srri {
	float: left;
	font-size: 15px;
	background: white;
	border: 1px solid $brand-secondary;
	color: $brand-secondary;
	text-align: center;
	font-weight: bold;

	$srri-count: 7;
	width: (100%/$srri-count);

	&:not(:first-child) {
		border-left: 0;
	}
}

.indic-srri-selected {
	color: white;
	background: $brand-secondary;
}
