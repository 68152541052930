﻿/// <reference path="../variables">
/// <reference path="../../bootstrap/variables">
/// <reference path="../mixins">

@mixin qt-table-cell($font-size) {
    vertical-align: middle;
    border-top: none;
    font-size: $font-size;
}

@mixin bootstrap-table-override($font-size) {
    & > thead {
        & > tr {
            & > td {
                @include qt-table-cell($font-size);
            }

            & > th {
                @include qt-table-cell($font-size);
            }
        }
    }

    & > tbody {
        & > tr {
            & > td {
                @include qt-table-cell($font-size);
            }
        }
    }

    & > tfoot {
        & > tr {
            & > td {
                @include qt-table-cell($font-size);
            }
        }
    }
}

@mixin table-y-padding-custom($padding) {
    tbody:first-child {
        padding-top: 0;
    }

    tbody:last-child {
        padding-bottom: 0;
    }

    & > tbody {
        & > tr {
            & > td {
                padding-top: $padding;
                padding-bottom: $padding;
            }
        }
    }
}

table {
    /*Override class ".table" de bootstrap*/
    &.table {
        @include bootstrap-table-override($table-font-size);

        &.table-top-border {
            & tr {
                & td {
                    border-top: 1px solid $brand-light;
                }
            }
        }
    }

    &.table-form {
        width: 100%;
        @include bootstrap-table-override(14px);

        td {
            padding: 5px 4px 5px 0;
        }
    }

    & .th-no-border {
        & > th {
            border-bottom: none !important;
        }
    }
}

/*Override classes de DataTables.net*/
.dataTableFooter {
    & .pagination > li > a, & .pagination > li > span {
        padding: 4px 12px;
    }
}

table.dataTable {
    margin-bottom: 0 !important;
    max-width: 100% !important;
}

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
    background-color: #E8E8E8;
}

table.dataTable tbody tr.selected, table.dataTable tbody th.selected, table.dataTable tbody td.selected {
    color: initial;
}

table.dataTable > thead > tr {
    & > th.sorting, & > th.sorting_asc, & > th.sorting_desc {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 0px;
    margin-right: 10px;
    height: 30px;
}

div.dataTables_wrapper div.dataTableFooter {
    background-color: #F9F9F9;
    padding: 7px;
    height: 44px;
}

div.dataTables_wrapper div.dataTables_info {
    display: inline-block;
    vertical-align: middle;
    padding-top: 0;
    height: 30px;
    padding-top: 5px;
    vertical-align: top;
}

div.dataTables_wrapper div.dataTables_paginate {
    display: inline-block;
}

div.dataTables_wrapper div.dataTables_length {
    float: right;
    margin-left: 10px;
    display: inline-block;
    margin-right: 0;
}

div.dataTables_wrapper {
    & tr {
        & td {
            /*text-align:right;*/
        }
    }
}
@media (max-width: $screen-sm-max)  {
	.table-condensed {
		> thead,
		> tbody,
		> tfoot {
			> tr {
				> th,
				> td {
					padding: $table-condensed-cell-padding - 1;
				}
			}
		}
	}
}

@mixin table-color-variant($header-bg, $header-color, $row-striped-bg, $row-hover-bg: darken($row-striped-bg, 8%), $body-bg: transparent) {
	background-color: $body-bg;

	> thead {
		background-color: $header-bg;
		color: $header-color;

		> tr {
			> th {
				border-color: $header-bg;
			}
		}
	}

	> tfoot {
		color: $header-bg;
	}

	&.table-striped {
		> tbody > tr:nth-of-type(odd) {
			background-color: $row-striped-bg;
		}

		& tbody > tr > td {
			border-top: 1px solid darken($row-striped-bg, 10%);
		}
	}

	&.table-hover {
		> tbody > tr:hover {
			background-color: $row-hover-bg;
		}
	}

	&.table-by-section {
		> tbody > tr.section-head {
			background-color: darken($row-striped-bg, 10%);

			&.level-1 {
				background-color: darken($row-striped-bg, 35%);
				letter-spacing:1px;
			}

			&.level-2 {
				background-color: darken($row-striped-bg, 10%);
			}

			> td {
				border-top: 1px solid darken($row-striped-bg, 15%);
				border-bottom: 1px solid darken($row-striped-bg, 15%);
			}
		}
	}
}

.table.table-default {
    @include table-color-variant($brand-main, #fff, $brand-light);
}



.table-colorful {
    @include table-color-variant(#3D3D3D, #fff, $brand-light);

    & > thead {
        & > tr {
            & > th {
                padding-top: 12px;
                padding-bottom: 12px;
                font-size: 13px;
            }
        }
    }

    & tbody {
        & > tr {
            & > th {
                font-weight: bold;
                color: white;
                padding: 8px 10px;
                font-size: 12px;
            }
        }

        & .checkbox {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

.table.table-alt {
    @include table-color-variant($gray-light, #fff, $gray-lighter);
}

.table.table-green {
    @include table-color-variant($brand-green, #fff, $brand-light);
}


.table tr > td.cell-radio {
    padding-top: 13px;
}

.table.table-no-bottom-margin {
    margin-bottom: 0px;
}


.table.table-separate{
    border-collapse: separate;
}

.table.table-condensed-max {
    margin-bottom: 0px;

    & > thead {
        & > tr {
            & > td {
                padding: 0;
            }

            & > th {
                padding: 0;
            }
        }
    }

    & > tbody {
        & > tr {
            & > td {
                padding: 0;
            }
        }
    }

    & > tfoot {
        & > tr {
            & > td {
                padding: 0;
            }
        }
    }

    table-nowrap {
        & > tbody {
            & > tr {
                & > td {
                    white-space: nowrap;
                }
            }
        }
    }
}

.table.table-reduced-padding {

    td:first-child {
        padding-left: 0;
    }

    td:last-child {
        padding-right: 0;
    }

    & > tbody {
        & > tr {
            & > td {
                padding: 4px;
            }
        }
    }
}

.table.table-barometre {
    td:first-child {
        padding-left: 0;
    }

    td:last-child {
        padding: 0;
    }

    & > tbody {
        & > tr {
            & > td {
                padding: 4px;
            }
        }
    }
}

.table.table-y-padding-1 {
    @include table-y-padding-custom(1px);
}

.table.table-y-padding-h {
    @include table-y-padding-custom(5px);
}

.table.table-y-padding-small {
    @include table-y-padding-custom(9px);
}

.table.table-y-padding-med {
    @include table-y-padding-custom(15px);
}

.table.table-y-padding-large {
    @include table-y-padding-custom(20px);
}

.table-line-group-indicator {
    > tbody > tr {
        > td.line-group-indicator-cell {
            background: transparent;
            padding-left: 20px;
            position: relative;

            & > .line-group-indicator-element {
                position: absolute;
                width: 4px;
                height: 100%;
                left: 12px;
                bottom: 0;
            }
        }

        &.line-group-indicator-start {
            > td.line-group-indicator-cell {
                padding-top: 35px;
                vertical-align: bottom;

                & > .line-group-indicator-element {
                    height: 65%;
                }
            }
        }
    }

    > tfoot > tr > th.line-group-indicator-cell,
    > tfoot > tr > td.line-group-indicator-cell {
        padding-left: 20px;
    }

    > thead > tr > th.line-group-indicator-cell,
    > thead > tr > td.line-group-indicator-cell {
        padding-left: 20px;
    }
}

table.table-line-group-macro-indicator {
    > tbody > tr {
        &.line-group-macro:not(:first-child) {
            border-top: 20px solid white;
        }

        &.line-group-macro {
            max-height: 25px;
        }

        & td, th {
            .content-sm {
                font-size: 11px;
            }
        }

        & > td.line-group-macro-indicator-cell {
            background: transparent;
            position: relative;


            & > .line-group-macro-indicator-element {
                position: absolute;
                height: 100%;
                bottom: 0;
                left: 0;
            }
        }
    }

    > tfoot > tr > th.line-group-indicator-cell,
    > tfoot > tr > td.line-group-indicator-cell {
        padding-left: 20px;
    }

    > thead > tr > th.line-group-indicator-cell,
    > thead > tr > td.line-group-indicator-cell {
        padding-left: 20px;
    }

    & tr.row-mixte {
        & > td {
            font-size: 80%;
            font-style:italic;
            color:$brand-gray-dark;
        }
    }
}

.table {
    @media(max-width: $screen-xs-max) {
        > thead > tr > th.col-hidden-xs,
        > thead > tr > td.col-hidden-xs {
            width: 0;
            position: absolute;
            top: -9999em;
            left: -9999em;
        }

        > tbody > tr {
            > th.col-hidden-xs, > td.col-hidden-xs {
                width: 0;
                position: absolute;
                top: -9999em;
                left: -9999em;
            }
        }

        > tfoot > tr.col-hidden-xs {
            > th, > td.col-hidden-xs {
                width: 0;
                position: absolute;
                top: -9999em;
                left: -9999em;
            }
        }
    }
}


/*@media(min-width: $screen-sm-min) {*/
.table {
    &.table-seamless-inputs {
        table-layout: fixed;
        white-space: normal;

        & > tbody > tr > td.cell-input {
            padding-top: 0;
            padding-bottom: 0;

            & input.form-control {
                display: inline-block;
                font-size: 12px;
                height: 27px;
                margin: 0;
                padding-left: 4px;
                padding-right: 3px;
            }

            & .input-group-addon {
                height: 27px;
                padding: 2px 12px;
            }
        }

        & .form-group {
            margin-bottom: 0px;
        }
    }
}
/*}*/


.table.table-autoresponsive {
    border-collapse: collapse;
    width: 100%;
    /* Stack rows vertically on small screens */
    @media (max-width: 30em) {
        /* Hide column labels */
        thead tr {
            position: absolute;
            top: -9999em;
            left: -9999em;
        }
        /* Leave a space between table rows */
        tr + tr {
            margin-top: 1.5em;
        }
        /* Get table cells to act like rows */
        tr,
        td {
            display: block;
        }

        td {
            border: none;
            /* Leave a space for data labels */
            padding-left: 50%;
        }
        /* Add data labels */
        td:before {
            content: attr(data-label);
            display: inline-block;
            font-weight: bold;
            margin-left: -100%;
            width: 100%;
        }
    }
    /* Stack labels vertically on smaller screens */
    @media (max-width: 20em) {
        td {
            padding-left: 0.75em;
        }

        td:before {
            display: block;
            margin-bottom: 0.75em;
            margin-left: 0;
        }
    }
}

.white-hover:hover {
    background-color: white !important;
}

.color-light-bg {
    background: #F6F6F6;
}

.table-link-row {
    cursor: pointer;

    &:active {
        background-color: $brand-gray;
    }
}

.border-composition {
    border-left: 5px solid;
}
