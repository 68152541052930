﻿
// basé sur le polyfill https://github.com/WICG/focus-visible
//permet de n'afficher le focusring que quand c'est utile pour un utilisateur clavier
/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}
