﻿$tree-decoration-display: inline-block !default; // mettre à none si aucune déco
$tree-node-margin: 23px !default;

.tree {
	//reset UL default style
	display: block;
	list-style: none;
	padding: 0;
	width: 100%;
}

.tree-node-content {
	display: inline-block;
	width: 100%;
	font-size: 1em;
}

// FA codes:
// chevron-down : f078
// chevron-right : f054

.tree-node {
	width: 100%;
	margin-top: 10px;

	&.has-children {
		&[data-open="false"] {
			> .tree {
				display: none;
			}
		}

		&[data-open="true"] {
			> .tree-node-content::before {
				content: "\f078";
			}

			> .tree {
				display: inline-block;
			}
		}

		> .tree-node-content {
			&::before {
				display: $tree-decoration-display;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				content: "\f054";
				font-family: "Font Awesome 5 Pro";
				font-weight: 900;
				font-size: 1em;
				/*vertical-align: middle;*/
				margin-right: 5px;
			}
		}
	}

	> .tree {
		margin-left: $tree-node-margin;
	}
}
