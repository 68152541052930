﻿.modal {
    & .modal-xl {
        width: 70%;
    }

    & .pdf-viewer {
        padding: 1em 0;
        width: 100%;
        height: 500px;
        max-height: 100%;
    }
}
