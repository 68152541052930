﻿/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />
/// <reference path="../../bootstrap/mixins" />
/// <reference path="../../bootstrap/variables" />

.popover {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

a.btn.disabled, fieldset[disabled] a.btn {
    pointer-events:visible;
}