﻿/// <reference path="../variables">
/// <reference path="../../bootstrap/mixins/vendor-prefixes">

@mixin block-corners($width) {
    border-style: solid;
    border-width: $width;
    border-color: transparent;
    -moz-border-image: url('#{$image-path}border_blue_corners.png') 16 stretch;
    -o-border-image: url('#{$image-path}border_blue_corners.png') 16 stretch;
    -webkit-border-image: url('#{$image-path}border_blue_corners.png') 16 stretch;
    border-image: url('#{$image-path}border_blue_corners.png') 16 stretch;
}

@mixin fancy-corner($size, $color) {
    width: $size;
    height: $size;
    border: 4px solid $color;
}