﻿@import "../_variables.scss";

.quantatree {
	& > .dropdown-container {
		position: relative;
	}

	& .dropdown-menu {
		min-width: 255px;
		padding: 10px;
		border-top: none;
	}

	& .quantatree-filtre {
		display: inline-block;
		width: 80%;
	}

	& .quantatree-filtre-button {
		width: 65%;
	}

	& .quantatree-checkboxAll {
		margin-right: 10px;
	}

	& [data-fancytree] {
		max-height: 300px;
		overflow-y: auto;
		overflow-x: visible;
	}
	//classes permettant de customiser la dropdown du quantatree en fonction de la place dispo
	& .dropdown-maxspace-100 [data-fancytree] {
		max-height: 50px;
	}

	& .dropdown-maxspace-200 [data-fancytree] {
		max-height: 100px;
	}

	& .dropdown-maxspace-300 [data-fancytree] {
		max-height: 175px;
	}

	& .dropdown-maxspace-400 [data-fancytree] {
		max-height: 250px;
	}


	& .hidden-scroll {
		overflow-y: hidden !important;
		overflow-x: hidden !important;
	}

	& .quantatree-validate-text {
		font-size: 12px;
	}

	& .quantatree-div-validation {
		margin-bottom: -5px;
	}

	& .quantatree-div-selection-text {
		padding-top: 5px;
		font-size: 12px;
	}

	& .quantatree-ok {
		font-size: 14px;
		color: #008800;
	}

	& ul.fancytree-container ul {
		padding: 0 0 0 1em;
	}

	& span.fancytree-title {
		color: $brand-dark;
	}

	& span.fancytree-checkbox {
		color: $brand-dark;
	}

	& .fancytree-selected {
		color: $brand-paint;

		& span.fancytree-title {
			color: $brand-paint;
		}

		& span.fancytree-checkbox {
			color: $brand-paint;
		}
	}

	& span.fancytree-expander, span.fancytree-expander:hover {
		color: $brand-paint;
	}

	& .fancytree-unselectable .fancytree-checkbox.glyphicon-unchecked {
		width: 0px;

		&:before {
			display: none;
		}
	}
}
