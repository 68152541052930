﻿/// <reference path="../variables">
/// <reference path="../mixins">
/// <reference path="../../bootstrap/variables">
/// <reference path="../../bootstrap/mixins/center-block">
/// <reference path="../../bootstrap/mixins/clearfix">

$opacity: 0.7;

@mixin wizard-color-variant($text-color, $chip-bg, $chip-active-bg, $chip-finished-bg) {
    /*color: $text-color;*/
    /*.wizard-header {
        .wizard-current-step-title {
            color: $text-color;
        }
    }*/
    .wizard-step {
        &[data-wizard-finished="true"] {
            & > .wizard-chip {
                @include chip-variant($text-color, $chip-finished-bg, $chip-finished-bg);
            }

            & > .wizard-text {
                color: $chip-finished-bg;
            }
        }

        &[data-wizard-invalid="true"] {
            & > .wizard-chip {
                @include chip-variant($btn-danger-color, $btn-danger-bg, $btn-danger-bg);
            }

            & > .wizard-text {
                color: $btn-danger-bg;
            }
        }

        &[data-wizard-active="true"] {
            & > .wizard-chip {
                @include chip-variant($text-color, $chip-active-bg, $chip-active-bg);
            }

            & > .wizard-text {
                color: $chip-active-bg;
            }
        }
    }

    .wizard-chip {
        @include chip-variant($text-color, $chip-bg, $chip-bg);

        &[data-wizard-finished="true"] {
            @include chip-variant($text-color, $chip-finished-bg, $chip-finished-bg);
        }

        &[data-wizard-invalid="true"] {
            @include chip-variant($text-color, $btn-danger-bg, $btn-danger-bg);
        }

        &[data-wizard-active="true"] {
            @include chip-variant($text-color, $chip-active-bg, $chip-active-bg);
        }
    }

    .wizard-text {
        color: $chip-bg;

        &.active {
            color: $chip-active-bg;
        }

        &.finished {
            color: $chip-finished-bg;
        }
    }

    .progress-chip {
        @include chip-variant($chip-bg, $chip-bg, $chip-bg);

        &[data-wizard-finished="true"] {
            @include chip-variant($chip-finished-bg, $chip-finished-bg, $chip-finished-bg);
        }
    }
}

@include keyframes(check) {
    0% {
        @include transform(translateY(-100%) rotate(- 45deg) scale(0));
    }

    75% {
        @include transform(translateY(-100%) rotate(- 45deg) scale(1.2));
    }

    100% {
        @include transform(translateY(-100%) rotate(- 45deg) scale(1));
    }
}

@include keyframes(cross) {
    0% {
        @include transform(translateY(-50%) scale(0));
    }

    75% {
        @include transform(translateY(-50%) scale(1.2));
    }

    100% {
        @include transform(translateY(-50%) scale(1));
    }
}

.wizard {
    z-index: 1;
    /*min-height: 400px;*/
    @extend .wizard-default;

    &.wizard-animated {
        @include transition(height 300ms);
    }
}

.wizard-start-screen,
.wizard-header,
.wizard-content,
.wizard-footer {
    width: 100%;
}

$wizard-timer-width: 160px;

.wizard-header {
    height: 160px;
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;

    > .wizard-title-wrapper {
        min-height: 37px;

        @media(min-width: $screen-md-min) {
            margin-bottom: 25px;
        }

        position: relative;

        .wizard-current-step-title {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            // text-transform: uppercase;
            font-size: 20px;
            text-align: center;

            br {
                content: " ";

                &:after {
                    content: " ";
                }
            }

            &.velocity-animating {
                position: absolute;
            }
        }
    }

    > .timer-container {
        position: absolute;
        right: 0;
        top: 0;

        @include transition(top 0.25s);
    }

    @media(min-width: $screen-sm-min) {
        > .wizard-title-wrapper {
            /*padding-left: $wizard-timer-width;
            padding-right: $wizard-timer-width;*/
        }

        > .wizard-progress {
            margin-top: 16px;
            padding-left: $wizard-timer-width;
            padding-right: $wizard-timer-width;
        }
    }

    @media(max-width: $screen-xs-max) {
        > .wizard-title-wrapper {
            padding-left: $wizard-timer-width;
            padding-right: $wizard-timer-width;
        }

        > .wizard-progress {
            margin-top: 10px;
            width: 70%;
            float: left;
        }

        > .timer-container {
            width: 30%;
            float: left;
        }
    }

    &.affix {
        position: fixed;
        z-index: 2;
        top: 0;
        background: $brand-light;
        border-bottom: 1px solid $brand-gray-lighten;
        width: 100%;
        margin: auto;

        @media (min-width: $screen-sm-min) {
            width: $container-sm;
        }

        @media (min-width: $screen-md-min) {
            width: $container-md;
        }

        @media (min-width: $screen-lg-min) {
            width: $container-lg;
        }

        & ~ .wizard-content,
        & ~ .wizard-start-screen {
            padding-top: 160px;
        }

        &.velocity-animating {
            position: relative;
        }
    }

    &.compact {
        height: auto;

        & .wizard-title-wrapper {
            margin-bottom: 12px;
            min-height: 16px;
        }

        & .wizard-progress {
            margin-top: 0;
        }
    }
}

.wizard-title {
    display: block;
    text-align: center;

    &:after {
        content: " ";
        display: block;
        background-color: $brand-main;
        height: 4px;
        width: 70px;
        margin: auto;
        margin-top: 5px;
    }
}

.wizard-content {
    overflow: visible;
    position: relative;

    .wizard-scroller {
        overflow: hidden;
    }

    .fancy-panel-group {
        margin-bottom: 0;
    }

    .wizard-focusable {
        position: relative;
        // off
        opacity: $opacity;
        @include transition($transition-default);

        &.fancy-panel {
            // off
            opacity: 1;
            @include transition($transition-default);

            & > * {
                opacity: $opacity;
                @include transition($transition-default);
            }
        }
        //active
        &[data-focus-active="true"] {
            opacity: 1;

            &.fancy-panel > * {
                opacity: 1;
            }
        }


        &[data-valid="1"],
        &[data-valid="2"],
        &[data-valid="3"] {
            &:before {
                display: block;
                position: absolute;
                top: 50%;
            }
        }

        &[data-valid="1"] {
            &:before {
                content: " ";
                right: 25px;
                border-left: 4px solid $brand-success;
                border-bottom: 4px solid $brand-success;
                height: 12px;
                width: 22px;
                @include transform(translateY(-100%) rotate(- 45deg));
                @include animation(check 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55));
            }
        }

        &[data-valid="2"],
        &[data-valid="3"] {
            &:before {
                @include transform(translateY(-50%));
                @include animation(cross 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55));
            }
        }

        &[data-valid="2"] {
            &:before {
                right: 27px;
                height: 22px;
                width: 22px;
                content: url("/areas/ProfilRisque/Static/img/icon_cross.svg");
            }
        }

        &[data-valid="3"] {
            &:before {
                right: 22px;
                height: 34px;
                width: 30px;
                content: url("/areas/ProfilRisque/Static/img/icon_question_mark.svg");
            }
        }
    }

    .fancy-panel {
        padding: 20px;

        @media(min-width: $screen-md-min) {
            padding: 30px 20px;
        }

        @media(min-width: $screen-lg-min) {
            padding: 40px 30px;
        }

        > range-radio.wizard-focusable {
            &[data-valid="1"], &[data-valid="2"] {
                &:before {
                    right: 0;
                }
            }
        }

        &.question-empty {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        &.question-empty-2 {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &:last-child {
            padding-bottom: 60px;
        }
    }
}

.wizard-footer {
    position: relative;
    width: 100%;
    padding-top: 40px;
    z-index: 2;
    background: transparent;

    & .wizard-pager {
        top: -18px;
    }
}

.wizard-animated {
    & .wizard-panel {

        @include translate(0, 700px);

        @media print {
            @include translate(0, 0);
        }
    }
}

.wizard-progress {
	display: block;
	width: 100%;
	margin: 0 auto;
	text-align: center;

	.wizard-step {
		@media(max-width: $screen-sm-max) {
			margin: 0;
		}

		display: inline-block;
		margin: 0 24px;
		position: relative;

		&.wizard-step-10 {
			margin-left: 0;
		}
	}

	.mini-chip {
		display: inline-block;
		top: 15px;
		position: absolute;
		transform: translateX(-6px);
		@include chip-size(8px, 15px, 2px);
	}

	.wizard-chip {
		@media(max-width: $screen-sm-max) {
			margin: 4px;
		}

		display: inline-block;
		margin: 2px;
		cursor: pointer;
		border: 1px solid;
		font-weight: $font-weight-6;

		@include chip-size(30px, $content-font-size, 5px);
		@include transition($transition-default);

		&[data-wizard-active=true],&.chip-no-pointer{
			pointer-events: none;
		}
	}

	.wizard-text {
		margin: 2px auto 0;
		font-weight: $font-weight-5;
		font-size: 13px;

		@include transition($transition-default);

		@media(max-width: $screen-sm-max) {
			display: none;
		}
	}

	&.wizard-bg-color {
		background-color: $brand-light;
	}
}

.wizard-pager {
    margin: auto;
    display: block;
    padding: 1px 16px;
    border: 1px solid $brand-gray-lighten;
    width: 100%;
    max-width: 80px;
    text-align: center;
    background: white;
    color: $brand-dark;
    font-size: $font-size-base;
    position: absolute;
    left: 50%;
    @include centerer(true, false);

    & > .wizard-pager-current {
        font-weight: $text-strong-weight;
        font-size: $font-size-base * 1.5;
    }

    & > .wizard-pager-max {
        font-weight: $text-strong-weight;
        font-size: $font-size-base;
    }
}

.progress-tracker {
    display: block;
    margin: 30px auto 10px;
    text-align: center;
}

.progress-chip {
    display: inline-block;
    margin: 5px;

    @include chip-size(10px, 0, 0);
}

.wizard-panel {
    display: none;

    &[data-wizard-active="true"] {
        display: block;
    }

    @media print {
        display: block;
        margin-bottom: 10px;
    }
}

.wizard-start-screen {
    text-align: center;

    & .panel-body {
        @media(min-width: $screen-md-min) {
            padding: 40px 230px;
        }
    }

    & h2 {
        margin-top: 0;
    }

    & p {
        text-align: center;
        margin-bottom: 30px;
    }
}

.wizard-default {
    @include wizard-color-variant(white, $brand-gray-lighten, $brand-main, $brand-success);

    @include radio-color-variant($brand-gray, $brand-gray, $brand-light, $brand-main, darken($brand-gray, 15%), darken($brand-gray, 15%), darken($brand-light, 15%));

    @include column-color-variant($brand-gray-lighten, $brand-gray-lighten, darken($brand-gray-lighten, 15%), $brand-main);
}

.wizard-outil {
    @include wizard-color-variant(white, $brand-gray-lighten, $brand-success, $brand-main);

    @include radio-color-variant($brand-gray-light, $brand-gray, $brand-light, $brand-main, white, lighten($brand-gray, 15%), lighten($brand-light, 15%));

    @include column-color-variant($brand-gray-light, $brand-gray-lighten, white, $brand-main);


    .wizard-header.affix {
        width: 100%;
        z-index: 2;

        @media (min-width: $screen-sm-min) {
            width: $container-sm - 20px;
        }

        @media (min-width: $screen-md-min) {
            width: $container-md - 60px;
        }

        @media (min-width: $screen-lg-min) {
            width: $container-lg - 60px;
        }
    }
}
