﻿/// <reference path="../bootstrap/variables">
/// <reference path="../bootstrap//mixins">
/// <reference path="../quantalys/_variables">
/// <reference path="../quantalys/_mixins">
html body {

	#tarteaucitronRoot {
		& * {
		}

		div#tarteaucitronAlertBig {
			background: white !important;
			color: black;
			width: 40% !important;
			-webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
			-moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
			box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
			border-radius: 0px;
			font-family: Arial,sans-serif;

			& #tarteaucitronDisclaimerAlert {
				color: black;
				text-align: justify;
			}

			&:before {
				content: none;
			}

			& .tarteaucitronCTAButton.tarteaucitronAllow {
				background: $brand-paint;
				padding: 10px 20px;
				border-radius: 0px;

				& .tarteaucitronCheck {
					&:before {
						content: none;
					}
				}
			}

			#tarteaucitronCloseAlert {
				padding: 10px 20px;
				border-radius: 0px;
				color: white;
				background: $brand-gray;
			}

			#tarteaucitronPrivacyUrl {
				color: $brand-paint;
				text-decoration: underline;
				display: block;
				text-align: center;
				margin-left: auto;
				margin-right: auto;
				margin-top: 15px;
			}
		}

		#tarteaucitron {
			& #tarteaucitronClosePanel {
				background: $brand-paint;
			}

			& #tarteaucitronServices {
				& .tarteaucitronMainLine {
					border-color: $brand-paint !important;
					background: $brand-paint;
					border: 3px solid $brand-paint;
					border-left: 9px solid $brand-paint;
					border-top: 5px solid $brand-paint;
				}

				& .tarteaucitronTitle {
					& button {
						background: $brand-paint;
					}
				}
			}

			& #tarteaucitronInfo {
				background: $brand-paint;
				border-color: transparent;
			}

			& .tarteaucitronBorder {
				border: 2px solid $brand-paint;
				border-color: $brand-paint !important;
			}
		}
	}
}
