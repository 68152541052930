﻿@mixin rect-variant($background-color, $border-color) {
    background-color:$background-color;
    border:1px solid $border-color;
}

@mixin rect-size($width, $height) {
    width:$width;
    height:$height;
}

