﻿/// <reference path="../variables">
/// <reference path="../../bootstrap/variables">
/// <reference path="../mixins">

/*
    -- Permet de centrer les éléments (li) dans une navbar --
    ----------------------------------------------------
    A utiliser de cette manière
    <div class="navbar-center">
        <ul class="nav nav-tabs">
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>

*/
.navbar-center {
    text-align: center;

    & .nav {
        display: inline-block;
        float: none;
    }
}

.tabs {
    margin: 3px 10px;
    padding-left: 0;
    list-style: none;

    > .tabs { margin: 0 }

    > li {
        float: left;
        margin-bottom: 0;
        position: relative;
        display: block;
        text-align: center;
        @include clickable();

        > a {
            font-weight: normal;
            padding: 1px 5px;
            color: #a8a8a8;
            border: none;
            margin-right: 2px;
            line-height: 1.42857;
            border-radius: 0 0 0 0;
            position: relative;
            display: block;

            &:hover {
                color: #5c5c5c;
                text-decoration: none;
                background-color: initial;
                border: none;
                outline: 0;
            }

            &:focus {
                background-color: initial;
                border: none;
                text-decoration: none;
            }

            &:active {
                outline: 0;
            }
        }

        &:hover:after {
            background-color: #5c5c5c;
            height: 2px;
            content: " ";
            display: block;
            width: 100%;
            margin: auto;
        }

        &:after {
            content: " ";
            display: block;
            background-color: #a8a8a8;
            height: 2px;
            width: 90%;
            margin: auto;
        }
    }

    > li.active {
        > a {
            color: #5c5c5c;
            border: none;
            font-weight: bold;
            cursor: default;
            background-color: #fff;

            &:focus {
                color: #5c5c5c;
                border: none;
                cursor: default;
                background-color: #fff;
            }
        }

        &:after {
            content: " ";
            background-color: #5c5c5c;
            display: block;
            height: 3px;
            width: 90%;
            margin: auto;
        }
    }

    &:before {
        content: " ";
        display: table;
    }

    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

.tabs.condensed {
    > li {
        > a {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.tabs-menu {
	list-style: none;
	padding-top: 4px;
	padding-bottom: 0px;
	margin-left: 0px;
	margin-bottom: 0px;
	padding-left: 0px;

	> li {
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;


		> a {
			padding: 8px 10px;
			font-weight: bold;
			display: block;
			color: $tab-color;

			&:hover, &[aria-expanded="true"] {
				border-bottom: 3px solid $tab-color;
				text-decoration: none;
			}

			&:focus {
				text-decoration: none;
			}
		}

		> a.active {
			color: $tab-active-color;
			border-bottom: 3px solid $tab-active-color;
		}
	}

	.dropdown-menu, .dropdown-submenu {
		a.active {
			background-color: $brand-main;
			color: white;
		}
	}

	.dropdown-submenu {
		position: relative;

		& > .dropdown-menu {
			top: 0;
			left: 100%;
			margin-top: -6px;
			margin-left: -1px;
		}

		&:hover {
			& > .dropdown-menu {
				display: block;
			}
		}

		& > a {
			&:after {
				display: block;
				content: " ";
				float: right;
				width: 0;
				height: 0;
				border-color: transparent;
				border-style: solid;
				border-width: 5px 0 5px 5px;
				margin-top: 5px;
				margin-right: -10px;
				border-left-color: $brand-minor;
			}
		}
	}
}



.navbar-main {

	& .navbar-brand {
		&:hover,
		&:focus {
			color: $tab-active-color;
			background-color: white;
		}

		color: $tab-active-color;
		border-radius: 3px;
		background-color: white;
	}

	& .navbar-nav {
		.active {
			> a, > a:hover, a:focus {
				color: $brand-main;
				background-color: transparent;
				font-weight: bold;
			}
		}
	}
	/*border:none;*/
	border-radius: 3px;
	margin-bottom: 10px;
	background-color: transparent;
}
