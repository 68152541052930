﻿.quantapie-legend {
    margin-top: 30px;

    & .quantapie-item-legend {
        margin-bottom: 4px;
        float: left;
        width: 100%;
        padding-right: 10px;


        & .quantapie-percent-item {
            min-width: 60px;
            float: left;
            padding: 6px 10px;
            background-color: black; /* Doit être override par jQuery */
            color: white;
            text-align: center;
            font-weight: bold;
            margin-right: 8px;
        }

        & .quantapie-content-item {
            border-top-style: solid;
            border-top-width: 1px;
            border-top-color: black; /* Doit être override par jQuery */
            padding: 8px 0px;
            text-transform: uppercase;

            &.item-legend-small {
                font-size: 12px;
            }

            & .quantapie-title-item {

            }

            & .quantapie-montant-item {
                padding-right:8px;
                font-weight:bold;
            }
        }
    }
}

.quantapie-legend-sm {
    margin-top: 5px;

    & .quantapie-item-legend {
        margin-bottom: 4px;
        float: left;
        width: 100%;
        padding-right: 10px;
        /*& .quantapie-percent-item {
            min-width: 60px;
            float: left;
            padding: 1px 8px;
            background-color: black; // Doit être override par jQuery
            color: white;
            text-align: center;
            font-weight: bold;
            margin-right: 8px;
        }

        & .quantapie-title-item {
            border-top-style: solid;
            border-top-width: 1px;
            border-top-color: black; // Doit être override par jQuery
            padding: 2px 0px;
            text-transform: uppercase;

            &.item-legend-small {
                font-size: 12px;
            }
        }*/
        & .quantapie-percent-item {
            min-width: 60px;
            float: left;
            padding: 1px 8px;
            background-color: black; /* Doit être override par jQuery */
            color: white;
            text-align: center;
            font-weight: bold;
            margin-right: 8px;
        }

        & .quantapie-content-item {
            border-top-style: solid;
            border-top-width: 1px;
            border-top-color: black; /* Doit être override par jQuery */
            padding: 2px 0px;
            text-transform: uppercase;

            &.item-legend-small {
                font-size: 12px;
            }

            & .quantapie-title-item {
            }

            & .quantapie-montant-item {
                padding-right: 8px;
                font-weight: bold;
            }
        }
    }
}

.quantapie-inner-balloon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight:bold;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); // IE9 only
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color:rgb(100, 100, 100);
}