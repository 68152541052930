﻿/// <reference path="../_variables.scss" />

.icon-pdf {
    color:rgb(150, 20, 0);
}

.icon-excel {
    color:rgb(55, 150, 80);
}

[data-helper-explanation] {
	&::after {
		display: inline-block;
		opacity: 0.8;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: "Font Awesome 5 Pro";
		font-weight: 700;
		content: "\f059";
		margin-left: 4px;
	}
}

.max-size-logo {
    max-width:200px;
    max-height:120px;
}

.spritefonds {
    background: url('/images/sprites/fonds_sprite.png') no-repeat top left;
    font-size: 1px; /* pour l'Ipad : 1 sinon Safari ne gère pas */
    padding: 0;
    display: block;

    &.sprite-1g {
    background-position: 0 0;
    width: 143px;
    height: 26px;
    }

    &.sprite-2g {
        background-position: 0 -137px;
        width: 143px;
        height: 27px;
    }

    &.sprite-3g {
        background-position: 0 -276px;
        width: 143px;
        height: 26px;
    }

    &.sprite-4g {
        background-position: 0 -413px;
        width: 143px;
        height: 26px;
    }

    &.sprite-5g {
        background-position: 0 -550px;
        width: 143px;
        height: 26px;
    }

    &.sprite-1p {
        background-position: 0 -75px;
        width: 63px;
        height: 12px;
    }

    &.sprite-2p {
        background-position: 0 -212px;
        width: 63px;
        height: 12px;
    }

    &.sprite-3p {
        background-position: 0 -351px;
        width: 63px;
        height: 12px;
    }

    &.sprite-4p {
        background-position: 0 -488px;
        width: 63px;
        height: 12px;
    }
        
    &.sprite-5p {
        background-position: 0 -625px;
        width: 63px;
        height: 12px;
    }

    &.small {
        transform:scale(0.4);
    }
}

/* Icones "+" et "-" sur les barres verticales et horizontales */
$margin-small: 15px;
$margin-medium: 30px;
$margin-large: 50px;
hr { //.vr-r, .vr-l
    &.small {
        margin-top: $margin-small;
        margin-bottom: $margin-small;
    }

    &.medium {
        margin-top: $margin-medium;
        margin-bottom: $margin-medium;
    }

    &.large {
        margin-top: $margin-large;
        margin-bottom: $margin-large;
    }

    &.plus {
        overflow: visible;
        text-align: center;

        &:before {
            content: "+";
        }
    }

    &.egal {
        overflow: visible;
        text-align: center;

        &:after {
            content: "=";
        }
    }

    &:before {
        font-size: 32px;
        color: $brand-gray-lighten;
        border-radius: 1.33em;
        width: 1.34em;
        line-height: 1.33em;
        display: inline-block;
        margin-top: -0.6em;
        background: #fff;
        border: $brand-gray-lighten 1px solid;
        position: relative;
        top: -4px;
    }
}

.notation-quantalys {
    & .small {
        width: 15px;
    }

    & .medium {
        width: 20px;
    }

    & .large {
        width: 30px;
    }
}

.state-alert-in {
    color: $brand-warning;
}

.state-alert-out {
    color: $brand-minor-header;
}
