﻿table.quantasaw{
    & > thead > tr{
        & > th, & > td{
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.quantasaw-minimap{
    display: inline-block;
    float: right;

    & .fa-circle{
        font-size: 5px;
        margin-left: 5px;
        vertical-align: middle;
    }

    & .arrow-left{
        background-color: transparent;
        padding: 1px 5px;
    }

    & .arrow-right{
        background-color: transparent;
        padding: 1px 5px;
    }
}

.quantasaw-hidden{
    display: none;
}
