﻿/// <reference path="../../variables">
/// <reference path="../../mixins">

/* -- Légende des graphs -- */
.quantaline-legend {
    & .graph-legend {
        display: inline-block;
        margin-right: 15px;
        vertical-align: middle;
        padding: 3px 10px;
        background-color: transparent;

        &.legend-light {
            background-color: #F2F2F2;
        }

        &.graph-condensed {
            margin-right: 5px;
            padding: 2px 4px;
        }
        /* Items associés aux légendes de graph */
        & .item-legend {
            display: inline-block;
            background-color: gray;

            &.item-square {
                width: 8px;
                height: 8px;
            }

            &.item-circle {
                width: 10px;
                height: 10px;
                border-radius: 50%;

                &.border-circle {
                    border: 2px solid #FBBD55;
                    background-color: #F9DC81;
                }
            } 

            &.item-line {
                width: 10px;
                height: 2px;
                margin-bottom: 3px;
            }

            &.item-dashed-line {
                width: 14px;
                height: 5px;
                border-top: 1px dashed $brand-gray;
            }

            &.item-rect {
                width: 20px;
                height: 8px;
            }
        }

        & .legend-text {
            display: inline;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 12px;
            color: #547EB7;

            &.small {
                font-size: 11px;
            }

            &.large {
                font-size: 14px;
            }
        }
    }
}

/* Pour le label représentant l'évolution*/
.amcharts-graph-label {
    font-size: 14px;
    font-weight: bold;
}
