﻿
// Mixins (aka fonctions CSS)
@import "bootstrap/_mixins.scss";
@import "quantalys/_mixins.scss";

// Elements CSS de base
@import "quantalys/elements/_text.scss";
@import "quantalys/elements/_chip.scss";
@import "quantalys/elements/_button.scss";
@import "quantalys/elements/_checkbox.scss";
@import "quantalys/elements/_radio.scss";
@import "quantalys/elements/_input.scss";
@import "quantalys/elements/_table.scss";
@import "quantalys/elements/_panel.scss";
@import "quantalys/elements/_spacing.scss";
@import "quantalys/elements/_list.scss";
@import "quantalys/elements/_graph.scss";
@import "quantalys/elements/_icon.scss";
@import "quantalys/elements/_fancy-switch.scss";
@import "quantalys/elements/_triple-switch.scss";
@import "quantalys/elements/_tooltip.scss";
@import "quantalys/elements/_indicators.scss";
@import "quantalys/elements/_modal.scss";
@import "quantalys/elements/_form.scss";
@import "quantalys/elements/_focus.scss";

//Composants CSS complets
@import "quantalys/components/_panel.scss";
@import "quantalys/components/_tree.scss";
@import "quantalys/components/_quantatree.scss";
@import "quantalys/components/_range-radio.scss";
@import "quantalys/components/_parsley.scss";
@import "quantalys/components/_menu.scss";
@import "quantalys/components/_timer.scss";
@import "quantalys/components/_table.scss";
@import "quantalys/components/_search.scss";
@import "quantalys/components/_graph.scss";
@import "quantalys/components/_tabs.scss";
@import "quantalys/components/_grid.scss";
@import "quantalys/components/_navbar.scss";
@import "quantalys/components/_date-range-bar.scss";
@import "quantalys/components/_column-utilities.scss";
@import "quantalys/components/_typeahead.scss";
@import "quantalys/components/_quantasaw.scss";
@import "quantalys/components/_login.scss";
@import "quantalys/components/_quantasearch.scss";
@import "quantalys/components/_wizard.scss";
@import "quantalys/components/_publicite.scss";
@import "quantalys/components/_carrousel.scss";
@import "quantalys/components/_heatmap.scss";

//Modules Quantalys
@import "quantalys/components/modules/_quantapie.scss"; 
@import "quantalys/components/modules/_quantaline.scss";
@import "quantalys/components/modules/_quantatooltip.scss";
@import "quantalys/components/modules/_quantagauge.scss";

//Page
@import "quantalys/_style.scss";

//Popper.js (pour les infobulles)
@import "lib/_popper-js.scss";

@import "lib/_slick.scss";
@import "lib/_slick-theme.scss";
@import "libcustom/_tarteaucitron.scss";
