﻿/// <reference path="../variables">
/// <reference path="../mixins">

// redéfinition des tabs bootstrap
.nav-tabs {
	border-bottom: none;

	& > li {
		margin-bottom: 1px; //un peu plus de place pour quand les tabs se mettent sur plusieurs lignes
		
		&:after {
			content: " ";
			display: none; //hidden, show with :hover, or .active
			height: 3px;
			margin: auto;
			position: absolute; //prevents from moving following elements
			background-color: $brand-gray-lighten;
			width: 90%;
			left: 5%; //center based on 90% width
			margin-top:-2px;
		}


		&:hover:after {
			display: block;
		}

		& > a {
			padding: 3px 15px;
			color: $brand-gray-lighten;
			font-weight: bold;
			border: none;


			&:hover,
			&:focus {
				background-color: initial;
				border: none;
			}
		}

		&.active {
			&:hover:after,
			&:after {
				display: block;
				background-color: $brand-gray-darken;
				width: 100%;
				left: 0;
			}

			& > a,
			& > a:hover,
			& > a:focus {
				color: $brand-gray-darken;
				border: none;
			}
		}
	}

	&.nav-tabs-main {

		& .nav-item {
			vertical-align: middle;
		}

		& > li {
			/*border-bottom: 1px solid $brand-minor;*/
			background-color: #ececec;

			& a {
				padding: 8px 10px;
				color: $brand-gray-darken;
			}

			&:hover {
				/*background-color: none;*/
				&:after {
					content: normal;
				}
			}

			&.active {
				border-bottom: none;
				background-color: white;
				/*border-left: 1px solid $brand-minor;
                border-right: 1px solid $brand-minor;*/
				opacity: 1;
				/*&:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }*/
				& a {
					color: $brand-main;

					&:focus {
						border: none;
					}
				}

				&:after {
					content: normal;
				}

				&:hover {
					background-color: none;
				}
			}
		}
	}
}


    .nav-tabs-flex {
        width: 100%;
        @include flexbox();
        justify-content: space-between;
        align-items: center;

        > li {
            @include flex(0 1 auto);
        }
    }
    /**
 * Responsive Bootstrap Tabs by @hayatbiralem
 * 15 May 2015
 */
    @mixin ellipsis() {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        width: 100%;
    }

    @mixin icon-styles() {
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @mixin transform($transform) {
        -webkit-transform: $transform;
        -moz-transform: $transform;
        -ms-transform: $transform;
        -o-transform: $transform;
        transform: $transform;
    }

    @media screen and (max-width: 479px) {
        .nav-tabs-responsive {
            > li {
                display: none;
                width: 23%;

                > a {
                    @include ellipsis();
                    width: 100%;
                    text-align: center;
                    vertical-align: top;
                }

                &.active {
                    width: 54%;

                    &:first-child {
                        margin-left: 23%;
                    }
                }

                &.active,
                &.prev,
                &.next {
                    display: block;
                }

                &.prev,
                &.next {
                    -webkit-transform: scale(0.9);
                    transform: scale(0.9);
                }

                &.next > a,
                &.prev > a {
                    -webkit-transition: none;
                    transition: none;

                    .text {
                        display: none;
                    }

                    &:after,
                    &:after {
                        @include icon-styles();
                    }
                }

                &.prev > a:after {
                    content: "\e079";
                }

                &.next > a:after {
                    content: "\e080";
                }

                &.dropdown {
                    > a > .caret {
                        display: none;
                    }

                    > a:after {
                        content: "\e114";
                    }

                    &.active > a {
                        &:after {
                            display: none;
                        }

                        > .caret {
                            display: inline-block;
                        }
                    }

                    .dropdown-menu {
                        &.pull-xs-left {
                            left: 0;
                            right: auto;
                        }

                        &.pull-xs-center {
                            right: auto;
                            left: 50%;
                            @include transform(translateX(-50%));
                        }

                        &.pull-xs-right {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

.tabs {
    &.tabs-center {
        text-align: center;

        & li {
            float: none;
            display: inline-block;
        }
    }

    &.tabs-right {
        text-align: right;

        & li {
            float: none;
            display: inline-block;
        }
    }
}

    .tabs.quanta-tabs {
        & > li {
            &:hover {
                > a {
                    color: $brand-main;
                }

                &:after {
                    background-color: $brand-main;
                }
            }

            & > a {
                &:hover,
                &:focus {
                    color: $brand-main;
                }
            }

            &.active {
                &:after {
                    background-color: $brand-main;
                }

                & > a,
                & > a:hover,
                & > a:focus {
                    color: $brand-main;
                }
            }
        }
    }

.tabs-duree-cone {
    .qtjs-selectDuree {
        padding: 5px;

        &#customDuration {
            padding: 2px 5px;

            input{
                height: 25px;
            }
        }
    }
}
