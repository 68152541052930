﻿.quantagauge-module {
	& .quantaguage-chart {
		width:100%;
	}

	& .quantagauge-legend {
		/*float:right;*/
		font-size:13px;

		& .legend-item {
			& .legend-picture {
				width:12px;
				height:12px;
				float:left;
				margin-top:3px;
			}
		}
	}
}
