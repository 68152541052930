﻿/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $title-font-family;
	color: $title-color;
	font-size: $title-font-size;
	font-weight: $font-weight-7;

	.strong {
		font-family: $title-strong-font-family;
	}

	& small {
		color: $title-color;
		font-size: 0.85em;
	}

	&.weak {
		font-weight: $font-weight-4;
	}
}

body, p {
    font-family: $content-font-family;
    color: $content-color;
    font-size: $content-font-size;
}

em,
.em {
    font-family: $content-em-font-family;
}

strong,
.strong {
    font-family: $content-strong-font-family;
    font-weight: $font-weight-7;
}

h1, .h1 {
    font-size: $font-size-h1;
    margin: 0.7*$font-size-h1 0 0.6*$font-size-h1;
}

h2, .h2 {
    font-size: $font-size-h2;
    margin: 0.7*$font-size-h2 0 0.6*$font-size-h2;
}

h3, .h3 {
    font-size: $font-size-h3;
    margin: 0.7*$font-size-h3 0 0.6*$font-size-h3;
}

h4, .h4 {
    font-size: $font-size-h4;
    margin: 0.7*$font-size-h4 0 0.6*$font-size-h4;
}

h5, .h5 {
    font-size: $font-size-h5;
    margin: 0.7*$font-size-h5 0 0.6*$font-size-h5;
    color: $brand-main;
}

h6, .h6 {
    font-size: $font-size-h6;
    margin: 1.1*$font-size-h6 0 $font-size-h6;
}

.text-main {
    color:$brand-main;
}

.text-light, .text-minor {
    color:$brand-minor;
}

.text-paint {
    color: $brand-paint;
}

.text-green {
    color: $brand-green;
}

.text-gray{
	color: $brand-gray;
}

.text-small {
    font-size:10px;
}

.text-medium {
    font-size:12px;
}

.text-bold {
    font-weight: $font-weight-7;
}

label {
    font-family: $label-font-family;
    font-weight: $font-weight-7;
}

@mixin title-underlined($position, $color, $line-width, $line-color) {
    position: relative;
    color: $color;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: $title-bottom-thickness;
        width: $line-width;
        background: $line-color;
    }

    @if $position == "center" {
        display: block;
        text-align: center;

        &::after {
            margin-left: auto;
            margin-right: auto;
        }
    }

    @if $position == "left" {
        display: inline-block;

        @media(max-width: $screen-md-min) {
            text-align: center;
            margin-bottom: 30px;
            display: block;
        }

        &.top-panel-align {
            display: table;

            @media(max-width: $screen-md-min) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &::after {
            @media(max-width: $screen-md-min) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @if $line-width == 100% {
        @if $position == "left" {
            display: table;

            @media(max-width: $screen-md-min) {
                display: table;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @if $position == "center" {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }
        /*margin-left: auto;
        margin-right: auto;*/
    }
}


@mixin title-underlined-variant($position, $line-width) {

    @include title-underlined($position, $title-color, $line-width, $title-bottom-color);

    &-negative {
        @include title-underlined($position, white, $line-width, $brand-main);
    }

    &-dark {
        @include title-underlined($position, $brand-dark, $line-width, $brand-dark);
    }

    &-main {
        @include title-underlined($position, $title-bottom-color, $line-width, $brand-main);
    }

    &-white {
        @include title-underlined($position, white, $line-width, white);
    }

    &-darkline {
        @include title-underlined($position, $brand-main, $line-width, $brand-dark);
    }
}

.h-center {

    @include title-underlined-variant(center, $title-bottom-md);

    &-none {
        @include title-underlined-variant(center, 0);
    }

    &-small {
        @include title-underlined-variant(center, $title-bottom-sm);
    }

    &-full {
        @include title-underlined-variant(center, 100%);
    }
}

.h-left {

    @include title-underlined-variant(left, $title-bottom-md);

    &-none {
        @include title-underlined-variant(left, 0);
    }

    &-small {
        @include title-underlined-variant(left, $title-bottom-sm);
    }

    &-full {
        @include title-underlined-variant(left, 100%);
    }
}

.a-main-negative {
    color: lighten($brand-main, 20%); //$brand-main;
    &:hover {
        color: white;
    }

    &:visited {
        color: $brand-main;
    }
}
