﻿/// <reference path="../variables">
/// <reference path="../mixins">
/// <reference path="../../bootstrap/variables">

.chip {
    display: inline-block;
    border-width: 0;
    border-style: solid;
    text-align: center;

    @include chip-size(24px, 14px, 3px);
}

.chip-bordered {
    border-width: 2px;
}

.chip-large {
    @include chip-size(36px, 18px, 8px);

    &.chip-bordered {
        border-width: 4px;
    }
}

.chip-small {
    @include chip-size(14px, 8px, 1px);

    &.chip-bordered {
        border-width: 1px;
    }
}

.chip-xsmall {
    @include chip-size(8px, 4px, 0);

    &.chip-bordered {
        border-width: 1px;
    }
}

.chip-default {
    @include chip-variant($btn-main-color, $btn-main-bg, $btn-main-border);
}

.chip-warning {
    @include chip-variant($btn-warning-color, $btn-warning-bg, darken($btn-warning-bg, 15%));
}

.chip-danger {
    @include chip-variant($btn-danger-color, $btn-danger-bg, darken($btn-danger-bg, 15%));
}

.chip-success {
    @include chip-variant($btn-success-color, $btn-success-bg, darken($btn-success-bg, 15%));
}

.chip-unknown {
    @include chip-variant($btn-minor-color, $btn-minor-bg, darken($btn-minor-bg, 15%));
}

.badge {
    &.badge-primary {
        background-color:$brand-main;
    }
}
