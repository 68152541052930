﻿/// <reference path="../variables">
/// <reference path="../mixins">

$line-height-alea: 4px;
$input-addon-top: $line-height-alea / 2;
$label-line-height: $radio-size + $line-height-alea;

@mixin fancy-radio-color-variant($text-color, $text-checked-color, $border-color, $bg-color, $checked-color) {
	& + label {
		color: $text-color;

		&:before {
			border: 2px solid $border-color;
			background: $bg-color;
		}

		&:hover, &:focus {
			&:before {
				border-color: $checked-color;
				box-shadow: inset 0 1px 1px rgba(#fff, 0.1), 0 0 8px rgba($checked-color, 0.7);
			}
		}

		&:disabled {
			&:before {
				border-color: $border-color;
				background: lighten($border-color, 35%);
			}
		}
	}

	&:checked + label {
		color: $text-checked-color;
	}

	&:checked + label:before {
		background: $checked-color;
		box-shadow: inset 0 0 0 0px $checked-color;
	}
}

input[type=radio].fancy {
	visibility: hidden;
	position: absolute;

	&.input-inline + label {
		margin-bottom: 0;
		padding-top: 10px;
		padding-right: 15px;
	}

	& + label {
		padding-left: $radio-size + $space-between-input-label;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		line-height: $radio-size;
		color: $label-color;
		cursor: pointer;
		margin-bottom: 14px;


		&:before {
			content: " ";
			display: inline-block;
			position: absolute;
			left: 0;
			width: $radio-size;
			height: $radio-size;
			margin-right: $space-between-input-label;
			border: 1px solid $checkbox-border-color;
			border-radius: $radio-size;
			background: $radio-bg-color;
			text-align: center;
			vertical-align: middle;
			line-height: $radio-size;
		}
	}

	&:checked + label {
		color: $label-checked-color;
	}

	&:checked + label:before {
		background: $radio-color;
		box-shadow: inset 0 0 0 2px $radio-bg-color;
	}

	&.inline + label {
		display: inline-block;
		margin-right: 15px;
	}

	&.disabled {
		color: #BABEC1;
		cursor: default;

		& + label {
			color: #BABEC1;
			cursor: default;
		}
	}
}

.fancy.dark {
	@include fancy-radio-color-variant($brand-gray-light, white, $brand-dark, $brand-gray-light, $brand-main);
}

@mixin radio-color-variant($text-color, $text-checked-color, $border-color, $bg-color, $checked-color) {
	& .fancy-content {
		color: $text-color;
	}

	& input {
		& ~ .fancy-content:before {
			border: 2px solid $border-color;
			background: $bg-color;
		}

		&:focus ~ .fancy-content {
			&:before {
				border-color: $checked-color;
			}

			&:after {
				box-shadow: 0 0 0 .15rem $checked-color, 0 0 0 .25rem $checked-color;
				box-shadow: inset 0 1px 1px rgba(#fff, 0.1), 0 0 6px rgba($checked-color, 0.7);
			}
		}

		&:hover ~ .fancy-content {
			&:before {
				border-color: $checked-color;
			}

			&:after {
				box-shadow: 0 0 0 .15rem $checked-color, 0 0 0 .25rem $checked-color;
				box-shadow: inset 0 1px 1px rgba(#fff, 0.1), 0 0 6px rgba($checked-color, 0.7);
			}
		}

		&:disabled ~ .fancy-content {
			cursor: not-allowed;
			opacity: 1;

			&:before {
				border-color: lighten($border-color, 15%);
				background: darken($bg-color, 15%);
			}

			&:after {
				box-shadow: none;
			}
		}

		&:checked ~ .fancy-content {
			color: $text-checked-color;
			opacity: 1;

			&:before {
				background: $checked-color;
				box-shadow: inset 0 0 0 0px $checked-color;
			}

			&:after {
				box-shadow: none;
			}
		}
	}
}

@include keyframes(radio-selected) {
	0% {
		@include scale(0.9);
	}

	20% {
		@include scale(0.6);
	}


	100% {
		@include scale(1);
	}
}


label.fancy-radio {
	padding-left: $radio-size + $space-between-input-label;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	line-height: $label-line-height;
	cursor: pointer;
	margin-bottom: 0;
	min-width: $radio-size;
	min-height: $radio-size;

	& input[type=radio] {
		position: absolute;
		z-index: -1;
		opacity: 0;

		& ~ .fancy-content:before {
			content: " ";
			display: inline-block;
			position: absolute;
			left: 0;
			top: $input-addon-top;
			width: $radio-size;
			height: $radio-size;
			margin-right: $space-between-input-label;
			border-style: solid;
			border-width: 2px;
			border-radius: $radio-size;
			text-align: center;
			vertical-align: middle;
			line-height: $radio-size;

			@include transition($transition-default);
		}

		& ~ .fancy-content:after {
			content: " ";
			display: inline-block;
			position: absolute;
			left: 0;
			top: $input-addon-top;
			width: $radio-size;
			height: $radio-size;
			border: 0;
			border-radius: $radio-size;
			line-height: $radio-size;
		}

		&:checked {
			& ~ .fancy-content:before {
				@include animation(radio-selected 0.18s cubic-bezier(0.68, -0.55, 0.265, 1.55));
			}
		}
	}

	@include radio-color-variant($label-color, $label-checked-color, $checkbox-border-color, $radio-bg-color, $brand-main);
	@include transition($transition-default);
}

.fancy-radio.dark {
	@include radio-color-variant($brand-gray-light, white, $brand-dark, $brand-gray-light, $brand-main);
}

.radio-list {
	position: relative;
	/*display: block;*/
	margin-top: 10px;

	& label.fancy-radio {
		display: block;
		margin-bottom: $radio-size + 1px;
	}

	& .list-item {
		margin-left: $radio-size + $space-between-input-label;
	}
}

.radio-list-inline {
	& label.fancy-radio {
		margin-right: 14px;
	}
}

label.button-radio {
	& input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
}
