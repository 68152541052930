﻿/// <reference path="../variables">
/// <reference path="../mixins">


$border-radius-input-header: 20px;
$header-line-height-header: 48px;
$logo-header-height-header: 15px;
$padding-top-header: 20px;

.border-top {
	height: 3px;
	width: 100%;
	position: relative;
	margin-top: $padding-top-header;
}

.border-top:first-child:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 3px;
	/*background:linear-gradient(to right, rgb(28,69,150), rgb(180,180,180));*/
	background: linear-gradient(to right, $brand-main-header, $brand-minor-header);
}

.logo-quantalys-header {
	max-width: 150px;
	height: auto;
	text-align: center;

	&.logo-big {
		max-width: 220px;
	}
}

.menu-quantalys {
	background-color: white;
	box-shadow: -0px 2px 5px 1px rgba(0, 0, 0, 0.1);
	min-height: 169px;
	padding-top: 20px;
	position: relative;

	&.min-heigth-120 {
		min-height: 120px;
	}

	& .menu-horizontal {
		line-height: $header-line-height-header;

		& .form-horizontal {
			display: inline-block;
			vertical-align: middle;
		}
	}

	& .menu-membre {
		min-width: 20%;
	}

	& .navbar-brand {
		padding: 8px 20px;
		padding-right: 30px;
	}
	/*& .submenu-offset {
        pos
    }*/
	& .navbar {
		margin-bottom: 0px;
	}

	& .navbar-default {
		background-color: white;
		border: none;

		& .navbar-nav {
			& a {
				/*color: rgb(180,180,180);*/
				color: $brand-gray;
				font-weight: $font-weight-7;
				/*border-right:1px solid #d5d5d5;
                border-left:1px solid #fff;*/
				&:hover {
					/*color: rgb(28,69,150);*/
					color: $brand-paint-header;
				}

				&:focus {
					color: $brand-main-header;
				}
			}
			/* Utilisé pour rendre la navbar du menu Quantalys justifié (en complément de la classe "nav-justified" de Bootstrap) */
			&.nav-justified {
				& > li {
					float: none;
					/*overflow:hidden;*/
					white-space: nowrap; //Pour forcer l'écriture des items sur une ligne
				}
			}

			& .nav-active {
				& a {
					color: $brand-paint-header;
				}
			}
		}
	}

	& .nav {
		& li {
			& a {
				padding-left: 6px;
				padding-right: 6px;
			}
		}
	}

	& .input-group {
		& .form-control {
			&:first-child {
				/*border-top-right-radius: $border-radius-input;
                border-bottom-right-radius: $border-radius-input;*/
				border-right: none;
			}
		}
	}

	& .btn-connexion {
		border-radius: 0px;
		background-color: rgb(200,200,200);
		color: whitesmoke;
		text-transform: uppercase;
		/*display: inline;*/
		padding: 4px 8px;
		border: none;
		font-weight: bold;
		letter-spacing: 1px;

		&:hover {
			background-color: rgb(100,100,100);
		}
	}

	& .menu-top-navigation {
		padding-right: 10px;
		position: relative;
		z-index: 1000;
		line-height: $header-line-height-header;
		text-align: right;

		& img {
			height: $logo-header-height-header;
			width: auto;
			padding-right: 2px;
			padding-left: 2px;
			transition: height 0.2s;

			&:hover {
				height: $logo-header-height-header + 4px;
			}
		}

		& a {
			text-decoration: none;
		}
	}

	& .search-input-group {

		& .input-group-addon {
			background-color: white;
			border: 1px solid #ccc;
			border-top-right-radius: $border-radius-input-header;
			border-bottom-right-radius: $border-radius-input-header;
			border-left: none;
		}

		& input:not(:first-child):not(:last-child) {
			transition: ease;
			transition-delay: 0s;
			transition-duration: 1s;
			border-radius: $border-radius-input-header;
			border-right: none;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			/*&:hover {
                background-color: $brand-main;
                color: white;
            }*/
		}

		& button {
			border: 0;
			background: transparent;

			&:focus {
				outline: none;
			}
		}
	}

	& .navbar-form {
		padding-right: 0px;
	}

	& .navbar-submenu {
		text-align: center;

		& .submenu-li {
			display: inline-block;
			margin: 0;
			padding: 0;

			& li {
				& a {
					padding-top: 15px;
					padding-bottom: 13px; //15px
					color: $brand-gray;
					font-weight: bold;
					//28.11.2017
					background-color: white;

					&:hover, &.active {
						background-color: rgb(28,69,150);
						color: whitesmoke;
						text-decoration: none;
					}
				}
			}
		}
	}

	& .navbar-toggle-xs {
		float: left;
		margin: 0;
	}
	/* Css pour l'affichage du sous menu */
	& .submenu-position {
		position: absolute;
		border-top: 1px solid rgb(240,240,240);
		border-bottom: 1px solid rgb(240,240,240);
	}

	& .submenu-li {
		float: left;
		list-style: none;
		padding-left: 0px;
	}

	& .submenu-li > li {
		//display:inline-block;
		float: left;
	}
	/* Ecran LG */
	@media screen and (min-width:$screen-md-max) {
		& .submenu-position {
			width: $container-large-desktop * 0.8;
		}
	}
	/* Ecran MD */
	@media screen and (min-width:$screen-sm-max) and (max-width:$screen-md-max) {
		& .submenu-position {
			width: $container-desktop * 0.8;
		}
	}
	/* Ecran SM */
	@media screen and (min-width:$screen-xs-max) and (max-width:$screen-sm-max) {
		& .submenu-position {
			width: $container-tablet;
		}
	}
	/* Ecran XS */
	@media screen and (max-width:$screen-xs-max) {
		& .submenu-position {
			width: auto;
			margin-left: auto;

			& .submenu-li {
				display: block;
				width: 100%;
			}
		}
	}

	& .client-login {
		font-size: 12px;
		font-weight: bold;
	}

	& .position-main-menu {
		position: relative;

		& > .logo-partenaire {
			min-width: 10%;
			max-width: 20%;
		}

		& > div {
			display: inline-block;
		}

		& .popover-connexion {
			position: absolute;
			right: 0;
			top: 50px;
			max-width: 200px;
			max-height: 40px;
			overflow: hidden;
			font-size: 12px;
		}

		& .icon-logout-user {
			position: absolute;
			top: 0;
			right: 8px;
		}
	}
}

.menu-webclient {
	padding-bottom: 8px;

	& .menu-vertical-align {
		line-height: 50px;
	}

	& .menu-logo-partenaire {
		@media screen and (max-width:$screen-xs-max) {
			text-align: center;
		}
	}
}

.menu-i18n-lang {
	display: inline-block;
	line-height: 25px;

	& .dropdown-toggle {
		background-color: transparent !important;
		border-color: transparent;
		outline: 0;
	}

	& .dropdown-toggle > .caret {
		margin-left: 5px;
	}

	& .lang-choice {
		& > a {
			/*padding-left: 35px;*/
		}

		& .select-mark {
			margin-left: 5px;
		}
	}

	& .img-flag {
		display: inline-block;
		width: 16px;
		height: 12px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		margin-right: 5px;
	}
}

/* Media Query Smartphone */
/*@media screen and (max-width:$screen-sm) {
    .search-input-group {
        display: inline-table;
        vertical-align: middle;
    }
}*/

/* Media query pour moyen et grand écran */
@media screen and (min-width:992px) {
	.padding-lg {
		padding-left: 10px;
		padding-right: 10px;
	}

	.navbar-collapse {
		padding-left: 8px;
		padding-right: 8px;
	}
}

/* Media query pour tout sauf grand écran */
@media (max-width: 1200px) {
	.navbar-menu-quantalys {
		float: left;
		width: 100%;
		padding-right: 15px;
	}

	.navbar-recherche-quantalys {
		width: 50%;
		float: left;
		padding-left: 15px;
	}

	.position-header .menu-top-navigation {
		position: absolute;
		width: 98%;
	}

	.search-input-group {
		& .form-control {
			width: 100% !important;
		}
	}
}

.main-menu-float {
	max-width: 62%;
}

.search-menu-float {
	float: right;
	max-width: 20%;
	min-width: 15%;

	& .input-group-rounded {
		transition: ease;
		transition-delay: 0s;
		transition-duration: 1s;
		border-radius: $border-radius-input-header;
		border-right: none;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	& .input-search-rounded button {
		background-color: white;
		border: 1px solid #ccc;
		border-top-right-radius: $border-radius-input-header;
		border-bottom-right-radius: $border-radius-input-header;
		border-left: none;
	}

	& .typeahead__list {
		right: 0;
		width: 150%;
		left: unset;
	}
}

/* Gestion du menu pour tablette */
@media (min-width:$screen-sm-min) and (max-width: $screen-md) {
	.menu-quantalys {
		& .position-main-menu {
			//position: relative; //rom 26.07
			position: absolute;
			top: $padding-top-header;
		}
	}

	.search-menu-float {
		float: none;
		position: absolute;
		/*width:30% !important;*/
		left: 25%;
		max-width: 28%;
		/*min-width: 20%;*/
	}

	.logo-partenaire {
		position: absolute;
		left: 10px;
	}

	.popover-connexion {
		position: absolute;
		right: 60px;
		top: 100px !important;
	}

	.main-menu-float {
		max-width: 100%;
	}

	.menu-membre {
		float: right;
		width: inherit;
		left: 0;
	}
}

/* Gestion du menu pour smartphone */
/*@media (min-width:$screen-xs-min) and (max-width:$screen-xs-max) {
    .menu-quantalys {
        .search-input-group {
            display: inline-table;
            vertical-align: middle;
        }


        & .position-main-menu {
            position: absolute;
            top: 10px;

            & > .logo-partenaire {
                max-width: 30%;
            }
        }

        & .menu-top-navigation {
            line-height: normal;
        }

        & .submenu-position {
            z-index: 10;
            width: 100%;
            position: relative;

            //Romain 27.07
            display:block;
            border:none;

            & .submenu-li {
                & > li {
                    width: 100%; // Chaque item du sous menu en smartphone devra prendre tout la place de la liste (un item par ligne) 
                    text-align: left;
                    padding-left: 10%;

                    & > a {
                        display: block;
                        padding-top:0;
                        padding-bottom:0;
                    }
                }
            }
        }
    }

    .navbar-collapse {
        box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
    }

    .main-menu-float {
        position: absolute;
        top: 50px;
        left: 10px;
        width: 100%;
        z-index: 10;
        max-width: 100%;
    }

    .search-menu-float {
        margin-left: 2%;
        float: none;
        max-width: 45%;
    }

    .popover-connexion {
        line-height: normal;
    }

    .menu-membre {
        float: right;
        width: inherit;
    }

    .cat-link {
        pointer-events:none;
        text-align:left;
    }
}*/

/* Smartphone et tablette */
@media (min-width:$screen-xs-min) and (max-width: 1020px) {
	.menu-quantalys {
		& .menu-top-navigation {
			& img:hover {
				height: $logo-header-height-header;
			}
		}
	}
}

.drop-content {
	a, a:hover {
		color: #5c5c5c;
		font-size: 12px;
	}
}

/*_______________[ MAJ ]_____________*/

/*.menu-quantalys .nav li.open ul {
    min-width: 696px;
    list-style: none;
    //padding-top: 20px;
}
.menu-quantalys .nav li.open ul li {
    @media(min-width: $screen-xs) {
        float: left;
    }
}*/
/* *** Menu Rework *** */
// new round typeahead :
// mettre "typeahead__container--round" à coté de typeahead__container : class="typeahead__container typeahead__container--round"
// et supprimer input-search-rounded et input-group-rounded
form .typeahead__container.typeahead__container--round {
	border-radius: $border-radius-input-header;
	border: solid 1px $input-border;


	& .typeahead__field input {
		border: 0 none;
		background: transparent;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		/*&:focus {
			background: transparent;
		}*/
	}

	& .typeahead__button button {
		border: 0 none;
		background-color: transparent;

		&:hover .typeahead__search-icon {
			border-radius: 7px;
			background-color: $typeahead-active-bg;
			outline: 0;
			//box-shadow: 0 0 0 5px = positionX positionY blur spread
			box-shadow: 0 0 0 5px $typeahead-active-bg;
		}
	}
}

// old round typeahead : a garder pour les endroits où on a pas appliqué le nouveau typeahead__container--round
.qt-menu-container {
	form .typeahead__container {
		& .input-group-rounded {
			transition: ease;
			transition-delay: 0s;
			transition-duration: 1s;
			border-radius: $border-radius-input-header;
			border-right: none;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		& .input-search-rounded button {
			background-color: white;
			border: 1px solid #ccc;
			border-top-right-radius: $border-radius-input-header;
			border-bottom-right-radius: $border-radius-input-header;
			border-left: none;
		}

		& .typeahead__list {
			right: 0;
			width: 150%;
			left: unset;
		}
	}
}




@media(max-width: $screen-xs-max) {
	.menu-quantalys .submenu-li {
		float: none;

		& > li {
			float: none;
		}
	}

	.menu-quantalys .navbar-submenu .submenu-li li a {
		display: inline-block;
		padding-top: 10px;
		padding-bottom: 5px;
		text-decoration: none;
	}

	.menu-quantalys .submenu-position {
		position: relative !important;
	}
}

.popover-connexion {
	position: relative;
	right: unset;
	top: unset !important;
}

.menu-quantalys .btn-account {
	border-radius: 0px;
	background-color: #c8c8c8;
	color: whitesmoke;
	text-transform: uppercase;
	/* display: inline; */
	padding: 4px 8px;
	border: none;
	font-weight: bold;
	letter-spacing: 1px;
}

.flex {
	@include flexbox();
	flex-wrap: wrap;

	&:after, &:before {
		display: block;
	}

	&.flex-center {
		align-items: center;
	}

	> [class*='col-'] {
		@include flexbox();
		flex-direction: column;
	}
	/* XS */
	@for $i from 1 through 10 {
		.flex-order-xs-#{$i} {
			order: $i;
		}
	}

	.vcenter {
		justify-content: center;
		vertical-align: middle;
		float: none;
	}

	@media(max-width: $screen-xs-max) {
		.vcenter-xs {
			justify-content: center;
			vertical-align: middle;
			float: none;
		}
	}
	/* SM */
	@media(min-width: $screen-sm) {
		@for $i from 1 through 10 {
			.flex-order-sm-#{$i} {
				order: $i;
			}
		}
	}

	@media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		.vcenter-sm {
			justify-content: center;
			vertical-align: middle;
			float: none;
		}
	}
	/* MD */
	@media(min-width: $screen-md) {
		@for $i from 1 through 10 {
			.flex-order-md-#{$i} {
				order: $i;
			}
		}
	}

	@media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
		.vcenter-md {
			justify-content: center;
			vertical-align: middle;
			float: none;
		}
	}
	/* LG */
	@media(min-width: $screen-lg) {
		@for $i from 1 through 10 {
			.flex-order-lg-#{$i} {
				order: $i;
			}
		}
	}

	@media(min-width: $screen-lg-min) {
		.vcenter-lg {
			justify-content: center;
			vertical-align: middle;
			float: none;
		}
	}
}
/* *** *** */

