﻿/// <reference path="../../bootstrap/mixins">
/// <reference path="../../bootstrap/variables">
/// <reference path="../mixins">
/// <reference path="../variables">

// RANGES

@mixin radio-color-variant($text-color, $border-color, $center-color, $selected-color, $hover-text, $hover-border, $hover-center) {
    .range-radio-item {
        & > label {
            color: $text-color;

            &:before {
                @include chip-variant(transparent, $selected-color, transparent);
            }

            &:after {
                @include chip-variant($range-radio-main-color, $center-color, $border-color);
            }

            .svg-icon-checkable {

                & path {
                    fill: $text-color;
                }
            }
        }

        & input {
            &:checked ~ label {
                color: $selected-color;
            }

            &:not(:checked):hover ~ label {
                color: $hover-text;

                & .svg-icon-checkable path {
                    fill: $hover-text;
                }

                &:after {
                    @include chip-variant($range-radio-main-color, $hover-center, $hover-border);
                }
            }
        }
        // Dessin des traits entre les points que sur ecran assez large pour aligner tous les points
        @media (min-width: $screen-md) {
            &:after {
                background-color: $border-color;
            }
        }
    }
}

.range-radio {
    width: 100%;
    text-align: center;
    margin: 0 auto 0;

    & > h4 {
        margin-bottom: 20px;
    }

    @media (min-width: $screen-md) {
        &.range-radio-wider {
            & .range-radio-item {
                width: 225px;
            }
        }
    }
}

.range-radio-item {
    display: inline-block;
    text-align: left;
    padding: 5px 20px 5px 25px;
    margin-bottom: 8px;

    @media (min-width: $screen-md) {
        width: 160px;
        text-align: center;
        padding: 0 15px;

        &.item-wider {
            width: 225px;
        }
    }

    position: relative;
    z-index: 1;

    @media (max-width: $screen-sm) {
        width: 100%;
    }

    & > label {
        display: inline-block;
        margin-top: 1px;
        margin-bottom: 0;
        @include clickable();

        @media (min-width: $screen-md-min) {
            display: block;
        }

        @media (max-width: $screen-sm-max) {
            width: 100%;

            & br {
                display: none;
            }
        }

        min-width: 70px;
        width: 100%;

        @include transition(all 0.2s);
        z-index: 1;

        &:before {
            content: "";
            display: block;
            z-index: 3;
            position: absolute;
            display: block;
            @include chip-size(16px,0,0);
            margin: 10px auto 10px;
            bottom: 0;
            left: 50%;
            @include transform(translateX(-50%) scale(0.2));
            opacity: 0;

            @media (max-width: $screen-sm-max) {
                position: absolute;
                left: 0;
                top: 50%;
                @include transform(translateY(-50%) scale(0.2));
                margin: 0;
            }

            border-style: solid;
            border-width: 2px;

            @include transition(transform 0.35s, opacity 0.3s);
        }

        &:after {
            content: "";
            z-index: 2;
            position: relative;
            display: block;
            @include chip-size(16px, 0, 0);
            margin: 10px auto 10px;

            @media (max-width: $screen-sm-max) {
                position: absolute;
                left: 0;
                top: 50%;
                @include translate(0, -50%);
                margin: 0;
            }

            border-style: solid;
            border-width: 2px;

            @include transition(all 0.3s);
        }

        &:before, &:after {
            @include transition-timing-function($timing-default);
        }
    }

    & input {
        opacity: 0; //instead of display none as we need the input displayed in order to hover it
        z-index: -1;
        position: absolute; //so it does not take space in the rendered page
        /* On modifie le label et son after en fonction de l'état de l'input */
        &:checked + label {
            &:before {
                opacity: 1;

                @include transform(translate(-50%) scale(1));

                @media (max-width: $screen-sm-max) {
                    @include transform(translateY(-50%) scale(1));
                }
            }

            &:after {
                @include transform(scale(0.5));

                @media (max-width: $screen-sm-max) {
                    @include transform(translateY(-50%) scale(0.5));
                }
            }
        }
    }

    & > .range-radio-label-bis {
        @media (min-width: $screen-md) {
            @include centerer(true, false);
        }

        &:before, &:after {
            display: none;
        }
    }
    // Dessin des traits entre les points que sur ecran assez large pour aligner tous les points
    @media (min-width: $screen-md) {
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 50%;
            bottom: 17px;
            z-index: -1;
        }

        &:last-child:after {
            display: none;
        }
    }
}

.inline-radio-list {
    float: left;
    padding-left: 15px;
    padding-right: 15px;

    & > input[type="radio"] {
        margin-top: 8px;
        margin-left: 5px;
    }
}

.range-radio-bright {
    @include radio-color-variant($brand-gray, $brand-gray, $brand-light, $brand-main, darken($brand-gray, 15%), darken($brand-gray, 15%), darken($brand-light, 15%));
}

.range-radio-dark {
    @include radio-color-variant($brand-gray-lighten, $brand-gray, $brand-light, $brand-main, white, lighten($brand-gray, 15%), lighten($brand-light, 15%));
}


@mixin column-color-variant($text-color, $column-color, $hover-color, $selected-color) {
    .range-column-item {
        & > label {
            color: $text-color;

            &:before {
                color: $column-color;
                background-color: $column-color;
            }
        }

        & input {
            &:checked + label {
                color: $selected-color;

                &:before {
                    @include chip-variant($selected-color, $selected-color, $selected-color);
                }

                &:after {
                    @include chip-variant($column-color, $selected-color, $selected-color);
                }
            }

            &:not(:checked):hover + label {
                color: $hover-color;

                &:before {
                    color: $hover-color;
                    background-color: $hover-color;
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            & > label {
                color: $text-color;
            }
        }
    }
}


//COLUMNS

.range-column {
    width: 100%;
    text-align: center;
    margin: 0 auto 0px;

    & > h4 {
        margin-bottom: 20px;
    }

    @media (max-width: $screen-sm-max) {
        margin: 0 auto 20px;
    }

    &.range-column-wider {
        & > range-column-item {
            width: 225px;
        }
    }

    &.range-column-auto {
        & > range-column-item {
            width: auto;
        }
    }
}

@mixin custom-height($height) {
    /*@include keyframes(animate-bar-#{$height}) {
        0% {
            height: 0;
        }

        100% {
            height: $height;
        }
    }*/
    @media (min-width: $screen-md-min) {
        height: $height;
        /*animation: animate-bar-#{$height} 0.3s ease-in;
        animation-delay: 600ms;*/
    }
}

$range-column-sizes: 14, 17, 20, 25, 28, 33, 40, 50, 55, 60, 66, 75, 80, 90, 100;

@each $size in $range-column-sizes {
    .range-column-#{$size} {
        @include custom-height(#{$size}px);
    }
}

.range-column-item {
    display: inline-block;
    text-align: left;
    padding: 5px 20px 5px 25px;
    margin-bottom: 8px;
    position: relative;
    z-index: 1;

    & > label {
        display: inline-block;
        margin-top: 1px;
        margin-bottom: 0;
        min-width: 70px;
        @include clickable();

        @include transition(all 0.2s);

        &:before {
            content: "";
            display: inline-block;
            width: 50px;
            border-style: solid;
            border-width: 2px;

            @include transition(all 0.2s);
        }
    }

    @media (min-width: $screen-md-min) {
        width: 160px;
        text-align: center;
        padding: 0 15px;

        &.item-wider {
            width: 225px;
        }

        &.item-auto {
            width: auto;
        }

        & > label {
            display: inline-block;

            &:before {
                display: block;
                margin: 0px auto 10px;
                height: 100%;
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        width: 100%;
        height: auto;
        margin-top: 1px;
        z-index: 1;
        position: relative;
        display: inline-block;
        margin-top: 1px;
        vertical-align: auto;

        & > label {
            width: 100%;

            & br {
                display: none;
            }

            &:before {
                top: 50%;
                @include translate(0, -50%);
                @include chip-size(16px, 0, 0);
                display: block;
                content: none;
            }

            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 50%;
                @include translate(0, -50%);
                border-style: solid;
                border-width: 2px;

                @include transition(all 0.2s);

                @include chip-size(16px, 0, 0);
            }
        }
    }

    & input {
        opacity: 0; //instead of display none as we need the input displayed in order to hover it
        z-index: -1;
        position: absolute; //so it does not take space in the rendered page
    }
}

.range-column-bright {
    @include column-color-variant($brand-gray-lighten, $brand-gray-lighten, darken($brand-gray-lighten, 15%), $brand-main);
}

.range-column-dark {
    @include column-color-variant($brand-gray-light, $brand-gray-lighten, white, $brand-main);
}



//BUTTONS


.button-radio {
    padding: 0;
}

.button-radio .radio-elt {
    float: left;
    margin: 0 10px;
    width: 120px;
    height: 35px;
    position: relative;
}

.button-radio label, .button-radio input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $brand-light;
    text-align: center;
    transition: all 0.3s;
}

.button-radio input[type="radio"]:checked + label,
.Checked + label {
    background: $brand-main;
    color: white;
    transition: all 0.3s;
}

.button-radio label {
    padding: 5px;
    border-radius: 1px;
    cursor: pointer;
    font-weight: $font-weight-5;
}

.button-radio label:hover {
    background: #DDD;
}
