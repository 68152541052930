﻿@import "../../../../Shared//Static/scss/quantalys/_variables.scss";

$brand-main: #FF5500; //#c45230
$brand-secondary: #00B2A1;


$btn-main-bg: $brand-secondary;
$btn-main-border: $btn-main-bg;


$content-color: #000;
$title-color: #464646;

$panel-default-border: $brand-main;

$amchart-text-color: $title-color;
$amchart-legend-color: $amchart-text-color;
$amchart-title-color: $amchart-text-color;
$amchart-axis-title-color: $amchart-text-color;

$amchart-border-color: $brand-main;
$amchart-axis-color: $amchart-border-color;
$amchart-axis-tick-color: $amchart-border-color;

// indicateurs, quartile
$min-width-quartile: 10px;
$max-width-quatile: 15px;

$brand-primary : $brand-main;
$pagination-color: $brand-secondary;
$pagination-active-bg: $brand-secondary;
$pagination-active-border: $brand-secondary;
