﻿// Panels

@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;

  & > .panel-heading {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;

    + .panel-collapse > .panel-body {
      border-top-color: $border;
    }
    .badge {
      color: $heading-bg-color;
      background-color: $heading-text-color;
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      border-bottom-color: $border;
    }
  }
}

@mixin panel-colored($heading-color, $body-color, $h-color) {
	> .panel-heading {
		background-image: none;
		background-color: $heading-color;
		border: 1px solid $heading-color;
	}

	h1, h2, h3, h4, h5, h6 {
		color: $h-color;
	}

	background-color: $body-color;
	border: none;
}
