﻿.bootstrap-col {
    &.col-small-padding {
        padding-left:8px;
        padding-right:8px;
    }

    &.col-xs-small-padding {
        padding-left:4px;
        padding-right:4px;
    }
}

.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;

    & .item {
        display:table;
        width:100%;
        height:100%;
	    border:1px solid #cecece;
        padding:16px 8px;
	    background:#ededed;
	    background:-webkit-gradient(linear, left top, left bottom,color-stop(0%, #f4f4f4), color-stop(100%, #ededed));
	    background:-moz-linear-gradient(top, #f4f4f4 0%, #ededed 100%);
	    background:-ms-linear-gradient(top, #f4f4f4 0%, #ededed 100%);
    }

    /* content styles */
    
    & .content {
	    display:table-cell;
	    vertical-align:middle;
	    text-align:center;
    }
    /*& .content:before {
        content:"Content";
        font-family:sans-serif;
        font-size:12px;
        letter-spacing:1px;
        color:#747474;
    }*/   
}

/*
    Par defaut les row ont -15px de marges à gauche et à droite car elles sont prévues pour être positionnées dans des .container qui appliquent 15px de padding à gauche et à droite.
    Si appliquer .container-fluid sur le parent n'est pas une option, et que la row est dans un block sans padding, alors utiliser cette classe.
*/
.row-no-margin{
    margin-left: 0;
    margin-right: 0;
}
